import { useState } from 'react'
import { Checkbox, Tooltip, Typography } from '@stationkim/front-ui'
import { CheckableCardContainer } from './styles'
import { Option } from '@/presentation/pages/paymentConfirmation/paymentConfirmation'

interface CheckableCardProps {
  id?: string
  name?: string
  option: Option
  onChange?: (checked: boolean) => void
  initialChecked?: boolean
  checked?: boolean
}

export const CheckableCard = ({
  id,
  name,
  option,
  initialChecked = false,
  checked: externalValue,
  onChange: externalOnChange,
}: CheckableCardProps) => {
  const [internalValue, setInternalValue] = useState(initialChecked)
  const value = externalValue !== undefined ? externalValue : internalValue

  const handleChange = (checked: boolean) => {
    if (typeof externalOnChange === 'function') {
      externalOnChange(checked)
    }

    if (externalValue === undefined) {
      setInternalValue(checked)
    }
  }

  return (
    <CheckableCardContainer id={id} checked={value}>
      <Tooltip title={option.label} placement='top'>
        <Typography>{option.label}</Typography>
      </Tooltip>
      <Checkbox
        name={name}
        id={id ? id + '_input' : ''}
        onChange={(e) => handleChange(e.target.checked)}
        checked={value}
      />
    </CheckableCardContainer>
  )
}
