import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom'

import { HeaderAndFooter } from '../components/templates/headerAndFooter'
import { VerifyAuthentication } from './verifyAuthentication'
import Cards from '../pages/cards'
import NewCard from '../pages/cards/newCard/newCard'
import FirstCopy from '../pages/cards/firstCopy'
import NewCardSecond from 'presentation/pages/cards/newCardSecond/newCardSecond'
import { PaymentConfirmation } from 'presentation/pages/paymentConfirmation'
import Home from '../pages/home'
import { OrderTracking } from '../pages/orderTracking/index'

//todo change to barrel
import Login from '../pages/login'
import PasswordDefinition from '../pages/passwordDefinition'
import CompanyRegistration from '../pages/company/register'
import RegistryMigration from 'presentation/pages/company/register/registryMigration'
import ForgotPassword from '../pages/forgotPassword'
import OrderPayment from '../pages/cards/orderPayment'
import Employees from '../pages/employees/list/employeeList'
import EmployeeForm from '../pages/employees/employeeForm'
import EmployeesImport from '../pages/employees/list/components/import'
import Recharges from '../pages/recharge/list'
import { Pix } from '../pages/pix/index'
import RechargeEmployeesSelection from '../pages/recharge/manual/rechargeEmployeesSelection'
import ManualRechargeValueDefinition from '../pages/recharge/manual/manualRechargeValueDefinition'
import { RechargePayment } from '../pages/recharge/rechargePayment'
import ImportRecharge from '../pages/recharge/import'
import RechargeImportReview from '../pages/recharge/import/components/rechargeImportReview'
import ConfirmOrderBroker from 'presentation/pages/recharge/broker/components/confirm'
import Reports from '../pages/reports'
import Users from '../pages/users'
import Departments from '../pages/departments/list'
import DepartmentEdit from '../pages/departments/edit'
import Financial from '../pages/financial'
import { CartOrderConfirmation } from '../pages/cartOrderConfirmation/cartOrderConfirmation'
import { Cart } from '../pages/cart/cart'
import { ErrorPage } from '../pages/errorPage'
import { BrokerImport } from '../pages/recharge/broker/brokerImport'
import { ImportResult } from '../pages/recharge/broker/importResults'
import { BrokerImportResults } from '../pages/recharge/broker/brokerImportResults/brokerImportResults'
import { CardsSelection } from '../pages/cardsSelection/cardsSelection'

export const router = createBrowserRouter([
  {
    errorElement: <ErrorPage isPublic />,
    children: [
      {
        path: 'login',
        element: <VerifyAuthentication isPublic element={<Login />} />,
      },
      {
        path: 'alterar_senha',
        element: <VerifyAuthentication isPublic element={<PasswordDefinition />} />,
      },
      {
        path: 'esqueci_minha_senha',
        element: <VerifyAuthentication isPublic element={<ForgotPassword />} />,
      },
      {
        element: (
          <HeaderAndFooter>
            <Outlet />
          </HeaderAndFooter>
        ),
        path: 'cadastro',
        children: [
          {
            index: true,
            element: <VerifyAuthentication isPublic element={<CompanyRegistration />} />,
          },
          {
            path: 'migrar',
            element: <VerifyAuthentication isPublic element={<RegistryMigration />} />,
          },
        ],
      },
      {
        path: 'cadastro/senha',
        element: <VerifyAuthentication isPublic element={<PasswordDefinition />} />,
      },
    ],
  },

  {
    element: (
      <HeaderAndFooter>
        <Outlet />
      </HeaderAndFooter>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <VerifyAuthentication element={<Home />} />,
      },
      {
        path: 'relatorios',
        element: <VerifyAuthentication element={<Reports />} />,
      },
      {
        path: 'financeiro',
        element: <VerifyAuthentication element={<Financial />} />,
      },
      {
        path: 'usuarios',
        element: <VerifyAuthentication element={<Users />} />,
      },
      {
        path: 'carrinho',
        children: [
          {
            index: true,
            element: <VerifyAuthentication element={<Cart />} />,
          },
          {
            path: 'confirmacao',
            element: <VerifyAuthentication element={<CartOrderConfirmation />} />,
          },
        ],
      },
      {
        path: 'departamentos',
        children: [
          {
            index: true,
            element: <VerifyAuthentication element={<Departments />} />,
          },
          {
            path: 'editar',
            element: <VerifyAuthentication element={<DepartmentEdit />} />,
          },
        ],
      },
      {
        path: '/cartoes',
        children: [
          {
            index: true,
            element: <VerifyAuthentication element={<Cards />} />,
          },
          {
            path: 'acompanhamento',
            element: <VerifyAuthentication element={<OrderTracking />} />,
          },
          {
            path: 'novo',
            children: [
              {
                index: true,
                element: <VerifyAuthentication element={<NewCard />} />,
              },
              {
                path: 'selecionar',
                element: (
                  <VerifyAuthentication
                    element={
                      <CardsSelection
                        title='Novo pedido de 1ª via de cartão'
                        linkToBack='/cartoes'
                        linkToNext={'/cartoes/novo'}
                      />
                    }
                  />
                ),
              },
              {
                path: 'primeira',
                element: <VerifyAuthentication element={<FirstCopy />} />,
              },
              {
                path: 'primeira',
                element: <VerifyAuthentication element={<FirstCopy />} />,
              },
              {
                path: 'segunda',
                element: <VerifyAuthentication element={<NewCardSecond />} />,
              },
              {
                path: 'segunda_confirmacao',
                element: <VerifyAuthentication element={<PaymentConfirmation />} />,
              },
              {
                path: 'pagamento/segunda',
                element: <VerifyAuthentication element={<OrderPayment />} />,
              },
            ],
          },
        ],
      },
      {
        path: 'colaboradores',
        children: [
          {
            index: true,
            element: <VerifyAuthentication element={<Employees />} />,
          },
          {
            path: 'cadastro',
            element: <VerifyAuthentication element={<EmployeeForm />} />,
          },
          {
            path: 'editar',
            element: <VerifyAuthentication element={<EmployeeForm />} />,
          },
          {
            path: 'visualizar',
            element: <VerifyAuthentication element={<EmployeeForm />} />,
          },
          {
            path: 'importacao',
            element: <VerifyAuthentication element={<EmployeesImport />} />,
          },
        ],
      },
      {
        path: 'recargas',
        children: [
          {
            index: true,
            element: <VerifyAuthentication element={<Recharges />} />,
          },
          {
            path: 'pix',
            element: <VerifyAuthentication element={<Pix />} />,
          },
          {
            path: 'acompanhamento',
            element: <VerifyAuthentication element={<OrderTracking />} />,
          },
          {
            path: 'novo_pedido_manual',
            children: [
              {
                index: true,
                element: <VerifyAuthentication element={<RechargeEmployeesSelection />} />,
              },
              {
                path: 'confirmacao',
                element: <VerifyAuthentication element={<PaymentConfirmation />} />,
              },
              {
                path: 'pagamento',
                element: <VerifyAuthentication element={<RechargePayment />} />,
              },
            ],
          },
          {
            path: 'novo_pedido_manual_cartoes',
            element: (
              <VerifyAuthentication
                element={
                  <CardsSelection
                    title='Pedido de recarga'
                    linkToBack='/recargas'
                    linkToNext={'/recargas/novo_pedido_manual'}
                  />
                }
              />
            ),
          },
          {
            path: 'novo_pedido_manual_valores',
            element: <VerifyAuthentication element={<ManualRechargeValueDefinition />} />,
          },

          {
            path: 'novo_pedido_importacao',
            element: <VerifyAuthentication element={<ImportRecharge />} />,
          },
          {
            path: 'novo_pedido_importacao_cartoes',
            element: (
              <VerifyAuthentication
                element={
                  <CardsSelection
                    title='Pedido de recarga'
                    linkToBack='/recargas'
                    linkToNext={'/recargas/novo_pedido_importacao'}
                  />
                }
              />
            ),
          },
          {
            path: 'novo_pedido_importacao_valores',
            element: <VerifyAuthentication element={<RechargeImportReview />} />,
          },
          {
            path: 'novo_pedido_importacao/confirmacao',
            element: <VerifyAuthentication element={<PaymentConfirmation />} />,
          },
          {
            path: 'novo_pedido_importacao/pagamento',
            element: <VerifyAuthentication element={<RechargePayment />} />,
          },
          {
            path: 'pedido_recarga_broker',
            children: [
              {
                index: true,
                element: <VerifyAuthentication element={<BrokerImport />} />,
              },
              {
                path: 'resultados_importacao',
                element: <VerifyAuthentication element={<ImportResult />} />,
              },
              {
                path: 'revisao',
                element: <VerifyAuthentication element={<BrokerImportResults />} />,
              },
              {
                path: 'dados',
                element: <VerifyAuthentication element={<ConfirmOrderBroker />} />,
              },
              {
                path: 'pagamento',
                element: <VerifyAuthentication element={<PaymentConfirmation />} />,
              },
              {
                path: 'confirmacao_pedido',
                element: <VerifyAuthentication element={<RechargePayment />} />,
              },
            ],
          },
          {
            path: 'pedido_recarga_broker/pagamento',
            element: <VerifyAuthentication element={<PaymentConfirmation />} />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to='/' replace />,
  },
])
