import { Title } from 'presentation/components'
import Dropzone from 'react-dropzone-esm'
import {
  Atention,
  CommonText,
  Container,
  InfoRow,
  InputFile,
  HighlightedText,
  NavLink,
  Wrapper,
  DownloadLink,
} from './style'
import { Icon } from 'presentation/components'
import { minTwoDigits } from '@/utils/functionUtils'

const ImportResult = ({ response, onDrop, ...props }) => {
  return (
    <>
      <Title.SubTitle>Resultado da Importação</Title.SubTitle>
      <Container>
        <InfoRow>
          <span>
            <HighlightedText>{minTwoDigits(response?.processed || 0)}</HighlightedText>
            <CommonText>Colaboradores no arquivo</CommonText>
          </span>
          <span>
            <HighlightedText>{minTwoDigits(response?.updated || 0)}</HighlightedText>
            <CommonText>Colaboradores atualizados</CommonText>
          </span>
          <span>
            <HighlightedText style={{ color: 'var(--color-primary-01)' }}>
              {minTwoDigits(response.new || 0)}
            </HighlightedText>
            <CommonText>Colaboradores novos importados</CommonText>
          </span>
          <span>
            <HighlightedText style={{ color: 'var(--color-secundary-01)' }}>
              {minTwoDigits(response.errors)}
            </HighlightedText>
            <CommonText>Erros na importação</CommonText>
          </span>
        </InfoRow>
        {response?.errors > 0 ? (
          <>
            <div style={{ textAlign: 'center' }}>
              <Atention>Atenção!</Atention>
              <CommonText>Os arquivos contendo erro não serão importados.</CommonText>
              <CommonText>Verifique os erros apontados no relatório de erros e importe novamente.</CommonText>
              {response?.report && (
                <DownloadLink>
                  <a download='erros_importacao.txt' href={`data:text/txt;base64,${response?.report}`}>
                    Ver relatório de erros <Icon name='external-link' />
                  </a>
                </DownloadLink>
              )}
            </div>
            <Dropzone accept='.txt, .xls' onDrop={onDrop} multiple={false} {...props}>
              {({ getRootProps, getInputProps }) => (
                <>
                  <InputFile {...getRootProps()}>
                    <Icon name='upload' /> Reenviar Modelo Preenchido
                  </InputFile>
                  <input {...getInputProps()} />
                </>
              )}
            </Dropzone>
          </>
        ) : (
          <>
            <Wrapper>
              <Title.SubTitle>Importação de colaboradores feita com sucesso!</Title.SubTitle>
              <NavLink to='/colaboradores'>Lista de Colaboradores</NavLink>
            </Wrapper>
          </>
        )}
      </Container>
    </>
  )
}

export default ImportResult
