import { useNavigate } from 'react-router-dom'
import {
  Content,
  Message,
  PageChangePrompt,
  PageHeader,
  Panel,
  Spacing,
  Spinner,
  Steps,
  TextDecoration,
} from 'presentation/components'
import api from '@/infra/api'
import {
  getRechageOrderDetails,
  patchRechargesExactValue,
  patchRechargeExactValue,
  patchRechargesValueByDays,
  patchRechargeValueByDays,
} from '@/services/rechargesServices'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import { formatMoney } from '@/utils/functionUtils'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useDispatch } from 'react-redux'
import GlobalRechargeModal from '../../../globalRechargeModal'
import { CustomInput, CustomTable, DescriptionText, StepsWrapper, TotalValue } from './styles'
import { FeatureToggle } from 'utils/featureToggle/featureToggle'
import { Button, Grid } from 'presentation/components/atoms'
import { useAddCartItem } from 'main/hooks/useAddCartItem'
import { ORDER_METHOD } from 'utils/constants'
import { useAtomValue } from 'jotai'
import { orderIdAtom } from '@/main/store'

const RechargeImportReview = ({ ...props }) => {
  const navigate = useNavigate()
  const { addToCart } = useAddCartItem()
  const orderId = useAtomValue(orderIdAtom)
  const [orderInfo, setOrderInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [employeesData, setEmployeesData] = useState(null)
  const [previousGlobalValues, setPreviousGlobalValues] = useState({ days: '', value: '' })
  const [error, setError] = useState(false)
  const daysInitialValue = useRef(null)
  const rechargeValueInitialValue = useRef(null)
  const [isGlobalOrderModalVisible, setIsGlobalOrderModalVisible] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      const { error, response } = await getRechageOrderDetails(orderId)
      setIsLoading(false)
      if (response) {
        setOrderInfo(response.data)
        setEmployeesData(response.data?.items)
      } else {
        dispatch(pushNotification(errorHandler(error.response)))
      }
    })()
  }, [orderId, dispatch])

  const cancelOrder = async () => {
    setIsLoading(true)
    try {
      await api.patch(`/orders-service/${orderId}/cancel`)
      setIsLoading(false)
      navigate('/recargas/novo_pedido_importacao', {
        state: { bypass: true },
      })
    } catch (e) {
      setIsLoading(false)
      setError('Erro ao cancelar')
    }
  }

  const generateTableRows = () => {
    return employeesData
      ? employeesData.map((employee, index) => (
          <CustomTable.Tr key={`recharge_employee_${index}`}>
            <CustomTable.Td>{employee.employeeName}</CustomTable.Td>
            <CustomTable.Td>{employee.operatorName}</CustomTable.Td>
            <CustomTable.Td>R$ {formatMoney(employee.cardBalance)}</CustomTable.Td>
            <CustomTable.Td>
              <CustomInput
                value={employee.totalQuantity} //
                placeholder='00'
                onFocus={(e) => {
                  daysInitialValue.current = e.target.value
                }}
                onBlur={(e) => {
                  const days = e.target.value
                  updateOrderValues(() => patchRechargeValueByDays(orderId, employee.itemId, days))
                }}
              />
            </CustomTable.Td>
            <CustomTable.Td>
              <CustomInput
                value={employee.totalValue}
                decimalSeparator={','}
                thousandSeparator={'.'}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'R$ '}
                placeholder='R$ 00,00'
                onFocus={(e) => {
                  rechargeValueInitialValue.current = moneyValueToServerFormat(e.target.value)
                }}
                onBlur={(e) => {
                  const value = moneyValueToServerFormat(e.target.value)
                  updateOrderValues(() => patchRechargeExactValue(orderId, employee.itemId, value))
                }}
              />
            </CustomTable.Td>
          </CustomTable.Tr>
        ))
      : null
  }

  const steps = [
    {
      name: 'Colaboradores',
      navigable: false,
    },
    {
      name: 'Informações',
      navigable: false,
    },
    {
      name: 'Pagamento',
      navigable: false,
    },
  ]

  const updateOrderValues = async (request) => {
    setIsLoading(true)
    const { error, response } = await request()
    setIsLoading(false)
    if (response) {
      setOrderInfo(response.data)
      setEmployeesData(response.data?.items)
      setIsGlobalOrderModalVisible(false)
    } else {
      dispatch(pushNotification(errorHandler(error.response)))
    }
  }

  const moneyValueToServerFormat = (string) => {
    return string.replace(',', '_').replaceAll('.', ',').replaceAll(' ', '').replace('_', '.').replace('R$', '')
  }

  return (
    <>
      <PageChangePrompt onlyConfirm>
        <p>Você não finalizou seu pedido.</p>
        <p>
          Seu pedido será salvo como <TextDecoration>Rascunho</TextDecoration> por uma semana e você poderá continuar de
          onde parou para finalizá-lo.
        </p>
      </PageChangePrompt>
      <Content limit='var(--content-width-limit)'>
        <Panel noPadding>
          <PageHeader>Pedido de Recarga por Importação</PageHeader>
          <Container fluid style={{ padding: '32px' }}>
            {isLoading || addToCart.isLoading ? (
              <Spinner.Box>
                <Spinner />
              </Spinner.Box>
            ) : (
              <>
                <Row>
                  <Col>
                    <StepsWrapper>
                      <Steps currentStep={2} steps={steps} />
                    </StepsWrapper>
                  </Col>
                </Row>
                <Row align='center'>
                  <Col>
                    <DescriptionText>
                      <p>Colaboradores e setores selecionados para o pedido</p>
                      <p>Revise as informações antes de continuar com seu pedido</p>
                    </DescriptionText>
                  </Col>
                  <Col sm={2}>
                    <Button variant='outlined' onClick={() => setIsGlobalOrderModalVisible(true)}>
                      Pedido Global
                    </Button>
                  </Col>
                </Row>
                <Spacing top='32px' />
                <Row>
                  <Col>
                    <CustomTable>
                      <thead>
                        <CustomTable.Tr>
                          <CustomTable.Th>Colaboradores</CustomTable.Th>
                          <CustomTable.Th>Benefícios associados</CustomTable.Th>
                          <CustomTable.Th>Saldo no cartão</CustomTable.Th>
                          <CustomTable.Th>Qtd dias</CustomTable.Th>
                          <CustomTable.Th>Valor Recarga</CustomTable.Th>
                        </CustomTable.Tr>
                      </thead>
                      <tbody>{generateTableRows()}</tbody>
                    </CustomTable>
                  </Col>
                </Row>
                <Row justify='end'>
                  <Col sm='content'>
                    <TotalValue>Valor total R$: {formatMoney(orderInfo?.subTotalValue)}</TotalValue>
                  </Col>
                </Row>
                <Spacing top='32px' />
                <Grid
                  container
                  spacing='16px'
                  justifyContent='flex-end'
                  direction={{ xs: 'column-reverse', md: 'row' }}
                >
                  <Grid item xs={12} sm={6} md={'auto'}>
                    <Button
                      onClick={() => {
                        cancelOrder()
                      }}
                      sx={{ width: { xs: '100%', sm: 'auto' } }}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <Grid item xs={12} md='auto'>
                    <Grid container spacing='16px'>
                      <Grid item xs={12} sm={6} md={'auto'}>
                        <Button
                          variant='outlined'
                          fullWidth
                          onClick={
                            orderInfo?.id
                              ? () =>
                                  navigate('/recargas/novo_pedido_importacao/confirmacao', {
                                    state: {
                                      orderId: orderInfo.id,
                                      bypass: true,
                                      method: ORDER_METHOD.IMPORT,
                                      title: 'Recarga',
                                      quantity: employeesData?.length,
                                      operatorCardName: employeesData.map((employee) => employee.operatorName)?.[0],
                                    },
                                  })
                              : null
                          }
                        >
                          Comprar agora
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6} md='auto'>
                        <FeatureToggle feature='cart'>
                          <Button
                            fullWidth
                            sx={{
                              whiteSpace: 'nowrap',
                            }}
                            onClick={() => addToCart.mutate({ orderId })}
                            variant='contained'
                            disabled={orderInfo?.hasTicketBalance}
                          >
                            Adicionar ao carrinho
                          </Button>
                        </FeatureToggle>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Container>
        </Panel>
      </Content>
      {error && (
        <Message type='error' onXClick={() => setError(false)}>
          Ocorreu um erro
        </Message>
      )}
      {isGlobalOrderModalVisible && (
        <GlobalRechargeModal
          open={isGlobalOrderModalVisible}
          onClose={() => setIsGlobalOrderModalVisible(false)}
          initialValues={previousGlobalValues}
          onConfirm={(inputs) => {
            if (inputs.days) {
              updateOrderValues(() => patchRechargesValueByDays(orderId, inputs.days))
              setPreviousGlobalValues({ days: inputs.days, value: '' })
            } else {
              const value = moneyValueToServerFormat(inputs.value)
              updateOrderValues(() => patchRechargesExactValue(orderId, value))
              setPreviousGlobalValues({ days: '', value: inputs.value })
            }
          }}
        />
      )}
    </>
  )
}

export default RechargeImportReview
