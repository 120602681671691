import { useEffect, useState } from 'react'
import { useAtomValue } from 'jotai'
import _ from 'lodash'
import moment from 'moment'
import { Content } from 'presentation/components'
import { Button, Divider, LoadingButton, Typography } from 'presentation/components/atoms'
import { formatMoney } from 'utils/functionUtils'
import { BasicInformationContainer, StyledPaper } from './styles'
import { useClosedCart } from './hooks/useClosedCart'
import { CartOrderConfirmationSkeleton } from './components/cartOrderConfirmationSkeleton/cartOrderConfirmationSkeleton'
import { useOrderSlip } from 'main/hooks/useOrderSlip'
import { OrderSummary } from 'presentation/components/molecules'
import { useFeatures } from 'main/hooks'
import { ORDER_TYPES } from 'main/utils/constants'
import { Pix } from './components/Pix/Pix'
import { AccountPersistentStore } from '@/main/store'
import { useLocation, useNavigate } from 'react-router-dom'

export const CartOrderConfirmation = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const orderId = location?.state?.orderId
  const orderType = location?.state?.orderType
  const account = useAtomValue(AccountPersistentStore)

  const [slip, setSlip] = useState('')

  const { features } = useFeatures()
  const { cart, isLoading } = useClosedCart({ cartId: orderId })

  const { isFetching: isLoadingSlip } = useOrderSlip({
    orderId: orderId,
    isCart: true,
    config: {
      onSuccess: (res: any) => setSlip(res?.data),
    },
  })

  useEffect(() => {
    if (!orderId) {
      navigate('/carrinho')
    }
  }, [orderId])

  const handleReturn = () => navigate('/recargas')
  const getBalancesUsageMessage = () => {
    if (cart.walletValue > 0 || cart.ticketingBalanceValue > 0) {
      const isBalanceUsed = cart.walletValue > 0
      const isTicketingBalanceUsed = cart.ticketingBalanceValue > 0
      const bothValuesConnection = isBalanceUsed && isTicketingBalanceUsed ? ' e ' : ''
      const accountText = isBalanceUsed
        ? `R$${formatMoney(cart.walletValue)} do seu saldo ${account?.resale?.digitalAccountName}`
        : ''
      const ticketingBalanceText = isTicketingBalanceUsed
        ? `R$ ${formatMoney(cart.ticketingBalanceValue)} do seu saldo da Conta Corrente`
        : ''
      return `Para este pedido você utilizou ${accountText}${bothValuesConnection}${ticketingBalanceText}.`
    }
    return ''
  }
  return (
    <Content>
      <StyledPaper>
        {isLoading ? (
          <CartOrderConfirmationSkeleton />
        ) : (
          <>
            <Typography variant='h2' id='purchaseConfirmation_title'>
              Pedido gerado!
            </Typography>
            <Typography variant='h5' sx={{ textAlign: 'center' }}>
              <span>Parabéns!</span> O seu pedido foi realizado com sucesso.
              {cart.slipValue > 0 && ' Efetue o pagamento para que ele seja efetivado.'}
              <br />
              {getBalancesUsageMessage()}
            </Typography>
            <Typography variant='h3' sx={{ fontWeight: 500 }}>
              Pedido: {cart.code}
            </Typography>
            <BasicInformationContainer>
              <Typography variant='h3'>
                <span>N° de itens:</span> <b>{cart.totalItens}</b>
              </Typography>
              <Divider orientation='vertical' flexItem />
              <Typography variant='h3'>
                Total a pagar: <b>R$ {formatMoney(cart?.totalValue)}</b>
              </Typography>
              <Divider orientation='vertical' flexItem />
              <Typography variant='h3'>
                Data:{' '}
                <b>{cart.orderDate ? moment(cart.orderDate).format('DD/MM/YYYY') : new Date().toLocaleDateString()}</b>
              </Typography>
            </BasicInformationContainer>
            {cart.slipValue > 0 && (orderType === 'SLIP' || orderType === 'TERM_BILL') && (
              <LoadingButton
                sx={{ width: '225px' }}
                variant='outlined'
                color='primary'
                size='small'
                onClick={() => window.open(slip, '_blank')}
                loading={isLoadingSlip || !slip}
                id='purchaseConfirmation_slipDownload_slip'
              >
                Baixar boleto
              </LoadingButton>
            )}
            {orderType === 'PIX' ? <Pix orderId={orderId} totalValue={cart?.totalValue} /> : null}
            {cart.orders.map((order) => (
              <OrderSummary
                hideSubtitle={true}
                values={[
                  { label: 'Colaboradores', value: order.totalItens, isMonetary: false },
                  {
                    label: order.orderType < ORDER_TYPES.RECHARGE ? 'Valor do pedido' : 'Valor da recarga',
                    value: order.totalRechargeValue,
                  },
                  { label: 'Taxa administrativa', value: order.administrateFee },
                  { label: 'Taxa de repasse', value: order.totalTransferFeeValue },
                  { label: 'Retenção ISS', value: order.issFee, isNegative: true },
                  features.ticketingBalance
                    ? { label: 'Conta corrente', value: order.ticketingBalance, hideZero: true, isNegative: true }
                    : null,
                  { label: 'Valor Total', value: order.totalValue, isBold: true, color: 'black' },
                ]}
                key={_.uniqueId()}
                order={order}
                showHeader
                id={'purchaseConfirmation_summary'}
              />
            ))}
            <Button
              id={'purchaseConfirmation_return_button'}
              sx={{ marginLeft: 'auto', width: '128px' }}
              variant='contained'
              onClick={handleReturn}
            >
              Voltar
            </Button>
          </>
        )}
      </StyledPaper>
    </Content>
  )
}
