import { Step } from 'presentation/components/atoms'
import { ORDER_STATUS, ORDER_STATUS_KEY } from 'main/utils/constants'
import { StepLabel, useMediaQuery, useTheme } from '@mui/material'
import { StyledStepper } from './styles'
import { waitingPaymentStatus } from './utils/waitingPaymentStatus'
import { runningPaymentStatus } from './utils/runningPaymentStatus'

export const selectStepColor = ({ status, palette }: { status: number; palette: any }): string => {
  let color = palette.primary.main
  if (status === ORDER_STATUS.WAITING_PAYMENT) color = palette.warning.main
  else if ([ORDER_STATUS.FINISHED].includes(status)) color = palette.success.dark
  else if ([ORDER_STATUS.CANCELED, ORDER_STATUS.CANCELING].includes(status)) color = palette.error.dark
  return color
}

const statusToStep = ({ status, isFinancedSales }: { status: number; isFinancedSales: boolean }) => {
  const stepsMap = [
    [ORDER_STATUS.EFFECTING, ORDER_STATUS.CANCELED, ORDER_STATUS.CANCELING],
    isFinancedSales && ORDER_STATUS.WAITING_PAYMENT ? [2] : [ORDER_STATUS.WAITING_PAYMENT, 99],
    [ORDER_STATUS.WAITING_FOR_PROCESSING, ORDER_STATUS.PROCESSING, ORDER_STATUS.RUNNING],
    [ORDER_STATUS.AVAILABLE_PICKUP, ORDER_STATUS.DELIVERING],
    [ORDER_STATUS.FINISHED],
  ]

  return stepsMap.findIndex((stepStatus) => stepStatus.includes(status))
}

export const OrderStatusStepper = ({
  orderStatus,
  isRecharge,
  isFinancedSales,
}: {
  orderStatus: number
  isRecharge: boolean
  isFinancedSales?: boolean
}) => {
  const { palette } = useTheme()
  const isVertical = useMediaQuery('@media (min-width:1020px)')
  const statusColor = selectStepColor({ status: orderStatus, palette: palette })
  const cancelStyle = [ORDER_STATUS.CANCELING, ORDER_STATUS.CANCELED].includes(orderStatus)
    ? { StepIconProps: { icon: 'X' } }
    : {}

  return (
    <StyledStepper
      statusColor={statusColor}
      activeStep={statusToStep({ status: orderStatus, isFinancedSales })}
      orientation={isVertical ? 'horizontal' : 'vertical'}
    >
      <Step>
        <StepLabel {...cancelStyle}>
          {[ORDER_STATUS.CANCELING, ORDER_STATUS.CANCELED].includes(orderStatus)
            ? ORDER_STATUS[orderStatus as ORDER_STATUS_KEY]
            : ORDER_STATUS[ORDER_STATUS.EFFECTING as ORDER_STATUS_KEY]}
        </StepLabel>
      </Step>
      <Step>
        <StepLabel>{waitingPaymentStatus({ status: orderStatus, isFinancedSales })}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{runningPaymentStatus({ status: orderStatus, isFinancedSales, isRecharge })}</StepLabel>
      </Step>
      <Step>
        <StepLabel>
          {[ORDER_STATUS.AVAILABLE_PICKUP, ORDER_STATUS.DELIVERING].includes(orderStatus)
            ? ORDER_STATUS[orderStatus as ORDER_STATUS_KEY]
            : ORDER_STATUS[ORDER_STATUS.FINISHED as ORDER_STATUS_KEY]}
        </StepLabel>
      </Step>
    </StyledStepper>
  )
}
