import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import All from './tabs/all/all'
import Orders from './tabs/orders/orders'
import Blocked from './tabs/blocked/blocked'
import Historic from './tabs/historic/historic'
import { Background, Tabs, TabList, Tab, TabPanel, Content, Panel, PageHeader } from 'presentation/components'
import { useDispatch, useSelector } from 'react-redux'
import errorHandler from '@/utils/errorHandler'
import { pushNotification } from '@/store/modules/notification/actions'
import { Col, Container, Row, useScreenClass } from 'react-grid-system'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

const Cards = () => {
  const { resources } = useAccessProfile({ resource: 'cartoes' })

  const location = useLocation()
  const indexDefault = location.state?.order ? 1 : 0
  const [tabIndex, setTabIndex] = useState(indexDefault)

  const screenClass = useScreenClass()
  const storeData = useSelector((state) => state.cards)
  const dispatch = useDispatch()

  useEffect(() => {
    if (storeData.failure !== null) {
      const errorMessage = errorHandler(storeData.failure)
      dispatch(pushNotification(errorMessage))
    }
  }, [storeData, dispatch])

  let formSubmit = null
  let isNavegable = true

  function handleTabNavigation(index) {
    formSubmit && formSubmit()
    if (!isNavegable) return
    setTabIndex(index)
  }

  return (
    <Content>
      <Panel noPadding>
        <Container fluid style={{ padding: 0 }}>
          <Row
            align='center'
            gutterWidth={16}
            justify={['xs', 'sm', 'md', 'lg'].includes(screenClass) ? 'center' : 'start'}
          >
            <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
              <PageHeader>Cartões</PageHeader>
            </Col>
          </Row>
        </Container>
        <Tabs
          selectedTabClassName='is-selected'
          disabledTabClassName='is-disabled'
          selectedTabPanelClassName='is-selected'
          selectedIndex={tabIndex}
          onSelect={handleTabNavigation}
        >
          <TabList>
            <Tab variant='underscore'>Todos</Tab>
            <Tab variant='underscore'>Pedidos</Tab>
            <Tab variant='underscore'>Bloqueados</Tab>
            {resources({ item: 'cartoes_historicodevia' }) ? <Tab variant='underscore'>Histórico de Vias</Tab> : null}
          </TabList>

          <TabPanel>
            <All />
          </TabPanel>
          <TabPanel>
            <Orders />
          </TabPanel>
          <TabPanel>
            <Blocked />
          </TabPanel>
          <TabPanel>
            <Historic />
          </TabPanel>
        </Tabs>
      </Panel>
    </Content>
  )
}

export default Cards
