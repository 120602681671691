import React from 'react'
import Dropzone from 'react-dropzone-esm'
import { Box, CircularProgress } from '@stationkim/front-ui'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Atention,
  CommonText,
  Container,
  InfoRow,
  InputFile,
  HighlightedText,
  DownloadLink,
  ButtonWrapper,
} from './style'
import { Icon } from 'presentation/components'
import { minTwoDigits } from '@/utils/functionUtils'
import { Button, Title } from 'presentation/components'
import { useBrokerOrderOld } from '../brokerImport/hooks/useBrokerOrderOld'
import { BrokerHOC } from '../brokerHOC'

export const ImportResult = () => {
  const { uploadImportFile } = useBrokerOrderOld()
  const {
    state: { error },
  } = useLocation()
  const navigate = useNavigate()

  function handleDrop(file) {
    if (file.length < 1) return
    const data = new FormData()
    data.append('files', file[0])
    uploadImportFile.mutate(data)
  }

  const handleGoBack = () => {
    navigate('/recargas/pedido_recarga_broker')
  }

  return (
    <BrokerHOC>
      {uploadImportFile.isLoading ? (
        <Box
          sx={{
            margin: 'auto auto',
          }}
        >
          <CircularProgress size='196px' />
        </Box>
      ) : (
        <>
          <Title.SubTitle style={{ margin: '32px 0px 0 32px' }}>Resultado da Importação</Title.SubTitle>
          <Container>
            <InfoRow>
              <span>
                <HighlightedText style={{ color: 'var(--color-primary-01)' }}>
                  {minTwoDigits(error.orderCount)}
                </HighlightedText>
                <CommonText>Colaboradores no pedido</CommonText>
              </span>
              <span>
                <HighlightedText style={{ color: 'var(--color-secundary-01)' }}>
                  {minTwoDigits(error.errorsCount)}
                </HighlightedText>
                <CommonText>Erros na importação</CommonText>
              </span>
            </InfoRow>
            <div style={{ textAlign: 'center' }}>
              <Atention>Atenção!</Atention>
              <CommonText>Os arquivos contendo erro não serão importados.</CommonText>
              <CommonText>Verifique os erros apontados no relatório de erros e importe novamente.</CommonText>
              {error?.base64 && (
                <DownloadLink>
                  <a download='erros_importacao.txt' href={`data:text/txt;base64,${error?.base64}`}>
                    Ver relatório de erros <Icon name='external-link' />
                  </a>
                </DownloadLink>
              )}
            </div>
            <Dropzone accept={['.xls', '.xlsx']} onDrop={handleDrop} multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <>
                  <InputFile {...getRootProps()}>
                    <Icon name='upload' /> Reenviar Modelo Preenchido
                  </InputFile>
                  <input {...getInputProps()} />
                </>
              )}
            </Dropzone>
            <ButtonWrapper>
              <Button variant='outlined' onClick={handleGoBack}>
                Voltar
              </Button>
            </ButtonWrapper>
          </Container>
        </>
      )}
    </BrokerHOC>
  )
}
