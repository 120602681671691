import { AxiosResponse } from 'axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import Decimal from 'decimal.js'
import api from '@/infra/api'
import { ETipoPagamento } from '@/main/enums'
import { useLocation, useNavigate } from 'react-router-dom'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { useOrderCancelation } from '@/main/hooks/useOrderCancelation'
import { Option } from '@/main/types'

type TDetail = {
  administrateFee: number
  totalTransferFeeValue: number
  ticketingBalance: number
  oldTicketingBalance: number
  issFee: number
  totalValue: number
  totalItens: number
  totalRechargeValue: number
  id: number
}

type TOrderData = {
  administrateFee: number
  transferFeeValue: number
  ticketingBalanceValue: number
  issFeeValue: number
  accountBalance: number
  totalBeforeBalanceUsage: number
  orderValue: number
  operatorsForBalanceProjection: Option[]
  daysForCredit: {
    unavailableDays?: string[]
    initialDate?: string
  }
}
interface IUseOrderReturn {
  order: TOrderData
  details: Array<TDetail>
  confirmOrder: any
  cancelOrder: () => void
  isLoading: boolean
}

interface GetInfoAPIResponse {
  valor: {
    valorTotal: number
    saldoCarteira: number
    taxasAdministracao: number
    taxasRepasse: number
    taxasIss: number
    valorRecarga: number
    dataParaCredito: string
    datasFeriados: string[]
    pedidos: Array<{
      id: number
      colaboradores: number
      tipoPedido: number
      valorTotal: number
      valorPedido: number
      taxaAdministrativa: number
      nomeOperadora: string
      taxaIss: number
      taxaIssCashback: number
      taxaSucesso: number
      taxaRepasseOperadora: number
      taxaRepasseOperadoraCashback: number
      taxaAproveitamentoDeSaldo: number
    }>
    operadoras: Array<{
      guidOperadora: string
      nomeOperadora: string
    }>
  }
  sucesso: boolean
  mensagens: string[]
}

export interface IConfirmOrder {
  totalBeforeBalanceUsage: number
  balanceUsage: number
  operatorsToUseBalanceProjection: string[]
  method: ETipoPagamento
  purchaseId: string
  creditDate: string
}

const _generatePayload = ({
  totalBeforeBalanceUsage,
  balanceUsage,
  operatorsToUseBalanceProjection,
  method,
  creditDate,
  purchaseId,
}: IConfirmOrder) => {
  const payload = {
    usarProjecaoDeSaldo: operatorsToUseBalanceProjection.length > 0,
    possuiProjecaoSaldo: operatorsToUseBalanceProjection.length > 0,
    projecaoSaldoOperadora: null,
    pagamentos: [],
    dataCredito: creditDate,
    idCompra: purchaseId,
  }

  if (payload.usarProjecaoDeSaldo) payload.projecaoSaldoOperadora = operatorsToUseBalanceProjection

  if (totalBeforeBalanceUsage === 0) return payload

  let valueToBePaid = new Decimal(totalBeforeBalanceUsage || 0)
  valueToBePaid = valueToBePaid.minus(balanceUsage || 0)
  payload.pagamentos = [
    {
      tipo: ETipoPagamento.CarteiraSK,
      valor: balanceUsage,
    },
    {
      tipo: method,
      valor: valueToBePaid.toNumber(),
    },
  ]
  payload.pagamentos = payload.pagamentos.filter((payment) => payment.valor > 0)
  return payload
}

export const useOrder = ({ purchaseId }: { purchaseId: string }): IUseOrderReturn => {
  const location = useLocation()
  const navigate = useNavigate()
  const notifications = useNotifications()

  const { data: rawData, isLoading: isInfosLoading } = useQuery<AxiosResponse<GetInfoAPIResponse>>({
    queryKey: ['cartOrdersByOrderId', purchaseId],
    queryFn: () => api.get(`rh-pedidos-bff/carrinho/confirmacao?idCompra=${purchaseId}`),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
    enabled: Boolean(purchaseId),
  })

  const organizeData = (): IUseOrderReturn['order'] => {
    const dataToOrganize = rawData?.data?.valor
    if (!dataToOrganize) return null
    return {
      administrateFee: dataToOrganize.taxasAdministracao,
      transferFeeValue: dataToOrganize.taxasRepasse,
      ticketingBalanceValue: 0, // precisa confirmar
      issFeeValue: dataToOrganize.taxasIss,
      orderValue: dataToOrganize.valorRecarga,
      accountBalance: dataToOrganize.saldoCarteira,
      totalBeforeBalanceUsage: dataToOrganize.valorTotal,
      operatorsForBalanceProjection: dataToOrganize.operadoras?.map((operadora) => ({
        label: operadora.nomeOperadora,
        value: operadora.guidOperadora,
      })),
      daysForCredit: {
        initialDate: dataToOrganize.dataParaCredito,
        unavailableDays: dataToOrganize.datasFeriados,
      },
    }
  }

  const organizeDetails = (): IUseOrderReturn['details'] => {
    const dataToOrganize = rawData?.data?.valor
    if (!dataToOrganize) return null
    return dataToOrganize.pedidos.map((order) => ({
      totalItens: order.colaboradores,
      totalValue: order.valorTotal,
      totalRechargeValue: order.valorPedido,
      operatorCardName: order.nomeOperadora,
      administrateFee: order.taxaAdministrativa,
      totalTransferFeeValue: order.taxaRepasseOperadora,
      ticketingBalance: 0,
      oldTicketingBalance: 0,
      issFee: order.taxaIss,
      id: order.id,
      code: order.id,
    }))
  }

  const details = organizeDetails()

  const confirmOrder = useMutation({
    mutationKey: ['finishBuying'],
    mutationFn: (rawPayload: IConfirmOrder) => {
      const payload = _generatePayload(rawPayload)
      return api.patch<{
        mensagens: string[]
        sucesso: boolean
        valor: {
          numeroPedido: number
          idCompra: string
          pedidos: Array<{
            idPedido: number
            codigoPedido: number
          }>
        }
      }>('rh-pedidos-bff/carrinho/confirmar-compra', { ...payload })
    },
    onSuccess: (res, { balanceUsage, method, operatorsToUseBalanceProjection, totalBeforeBalanceUsage, ...rest }) => {
      let valueToBePaid = new Decimal(totalBeforeBalanceUsage || 0)
      valueToBePaid = valueToBePaid.minus(balanceUsage || 0)
      const pathname =
        method === ETipoPagamento.Pix ? '/recargas/pix' : '/recargas/pedido_recarga_broker/confirmacao_pedido'
      const ordersCodes = res?.data?.valor?.pedidos
      const detailsWithOrderNumber = details.map((details) => {
        return { ...details, code: ordersCodes.find((order) => order.idPedido === details.id).codigoPedido }
      })
      navigate(pathname, {
        state: {
          ...rest,
          details: detailsWithOrderNumber,
          id: '',
          totalToPay: valueToBePaid.toNumber(),
          balanceUsage: balanceUsage,
          useBalanceProjection: operatorsToUseBalanceProjection.length > 0,
          bypass: true,
          numberOfColaborators: details.reduce((total, actual) => {
            return total + actual.totalItens
          }, 0),
          code: res?.data?.valor?.numeroPedido || '???',
          orderType: 'recharge',
          title: 'Recarga',
          isDealer: false,
          purchaseId: res?.data.valor?.idCompra,
        },
      })
    },
  })

  const { cancelPurchase } = useOrderCancelation()
  const cancelOrder = () => {
    if (location.state)
      cancelPurchase.mutate(
        { purchaseId: location.state?.purchaseId },
        {
          onSuccess: () => {
            navigate('/recargas')
            notifications.push({ type: EMessageType.Success, content: 'Pedido cancelado com sucesso!' })
          },
        },
      )
  }

  return {
    order: organizeData(),
    details,
    confirmOrder,
    cancelOrder: cancelOrder,
    isLoading: isInfosLoading || cancelPurchase.isLoading || confirmOrder.isLoading,
  }
}
