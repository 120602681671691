import { IconDefinition } from '@fortawesome/free-brands-svg-icons'
import { Icon } from '@stationkim/front-ui'
import { OptionContainer } from './styles'
//import { ESelectedPaymentMethod } from '../method'
import { useTheme } from '@mui/material'

interface OptionProps {
  icon: IconDefinition
  value: any //ESelectedPaymentMethod
  onClick?: (value: any /* ESelectedPaymentMethod */) => void
  checked?: boolean
  isNew?: boolean
  children?: React.ReactNode
  iconColor?: {
    checked?: string
    unchecked?: string
  }
}

export const Option = ({ icon, onClick, value, children, checked, iconColor, isNew }: OptionProps) => {
  const theme = useTheme()
  iconColor = { checked: theme.palette.primary.main, unchecked: theme.palette.grey[700], ...iconColor }

  return (
    <OptionContainer
      sx={{ width: { xs: '100%', sm: '180px' } }}
      checked={checked}
      onClick={() => onClick(value)}
      isNew={isNew}
    >
      <Icon icon={icon} sx={{ color: checked ? iconColor.checked : iconColor.unchecked }} />
      {children}
    </OptionContainer>
  )
}
