import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const StepsWrapper = styled.div`
  max-width: 800px;
  margin: auto;
  margin-bottom: 48px;
`

export const ContainerCards = styled.div`
  width: 472px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
`

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 81px;
  padding: 16px 0px 0px 0px;
  border-radius: 4px 0px 0px 0px;
  justify: space-between;
  padding: 0 15px;
  cursor: pointer;

  &:nth-of-type(2) {
    border-top: 1px solid rgba(224, 224, 224, 1);
  }

  &:nth-of-type(3) {
    border-top: 1px solid rgba(224, 224, 224, 1);
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    width: 100%;

    div {
      p {s
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.87);
      }

      small {
        font-size: 12px;
        font-weight: 400;
        color: rgba(158, 158, 158, 1);
      }
    }
  }
`

export const BackToList = styled.div`
  margin-top: 30px;

  a {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: rgba(21, 101, 192, 1);
    text-decoration: underline;
    box-sizing: border-box;
  }
`
