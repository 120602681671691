import { CheckboxField } from '@/presentation/components/molecules'
import { ProjectionContainer } from './styles'
import { EMessageType } from '@/main/store'
import { useNotifications } from '@/main/hooks'
import { useOrderOperatorsCards } from '@/presentation/pages/cart/hooks/useOrderOperatorsCards'
import { ProjectionCheckDialog } from './components/projectionCheckDialog'
import { useEffect, useState } from 'react'
import { Skeleton, Typography } from '@stationkim/front-ui'

interface BalanceProjectionProps {
  notAllowProjection?: boolean
  id?: string
  onChange: (
    projectionInfo: {
      operatorCardId: string
      balanceProjectionEnabled: boolean
    }[],
  ) => void
}

export const BalanceProjection = ({ onChange = () => null, notAllowProjection, id }: BalanceProjectionProps) => {
  const [selected, setSelected] = useState<string[]>([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { operatorsCards, isLoading } = useOrderOperatorsCards()
  const { push } = useNotifications()

  const selectAll = (operatorsCards: ReturnType<typeof useOrderOperatorsCards>['operatorsCards']) =>
    setSelected(operatorsCards.map((card) => card.id))

  useEffect(() => {
    if (selected.length >= 0) selectAll(operatorsCards)
  }, [operatorsCards])

  useEffect(() => {
    onChange(
      operatorsCards.map((operatorCard) => ({
        balanceProjectionEnabled: selected.includes(operatorCard.id),
        operatorCardId: operatorCard.id,
      })),
    )
  }, [selected])

  if (isLoading) return <Skeleton variant='rounded' height='74px' sx={{ marginTop: '22px' }} />
  if (operatorsCards.length === 0) return null

  const handleBalanceCheck = () => {
    if (selected.length > 0) setSelected([])
    else selectAll(operatorsCards)
  }

  return (
    <>
      <ProjectionContainer notAllowProjection={notAllowProjection}>
        <CheckboxField
          id={id}
          label={<b>Quero fazer a projeção de saldo</b>}
          onChange={(e) =>
            notAllowProjection
              ? push({
                  content:
                    'Não é possível realizar a projeção de saldo em pedidos que utilizam saldo da conta corrente bilhetagem',
                  type: EMessageType.Error,
                })
              : handleBalanceCheck()
          }
          value={notAllowProjection ? false : selected.length > 0}
        />
        <ProjectionCheckDialog
          open={isDialogOpen}
          options={operatorsCards}
          onConfirm={(selected) => {
            setSelected(selected)
            setIsDialogOpen(false)
          }}
          onClose={() => setIsDialogOpen(false)}
          initialSelection={selected}
        />
      </ProjectionContainer>
      {operatorsCards.length > 1 && (
        <button
          style={{
            all: 'unset',
            margin: 'auto',
            display: 'block',
            marginTop: '8px',
            cursor: 'pointer',
          }}
          onClick={() => setIsDialogOpen(true)}
        >
          <Typography
            sx={(theme) => ({
              color: theme.palette.grey[700],
              '& span': { textDecoration: 'underline' },
            })}
          >
            <span>Ver operadoras selecionadas</span> (<strong>{selected.length}</strong> de {operatorsCards.length})
          </Typography>
        </button>
      )}
    </>
  )
}
