import { useLocation, useNavigate } from 'react-router-dom'
import { IntegrationSwapper } from 'presentation/components'
import { PaymentConfirmation as PaymentConfirmationComponent } from './paymentConfirmation'
import { useOrder } from './hooks/useOrder'
import { OldPaymentConfirmation } from './oldPaymentConfirmation/oldPaymentConfirmation'
import { useEffect } from 'react'

const NewAPIIntegration = () => {
  const location = useLocation()
  const purchaseId = location.state?.purchaseId
  const { isLoading: isLoadingActions, confirmOrder, cancelOrder, order, details } = useOrder({ purchaseId })
  const isLoading = isLoadingActions
  return (
    <PaymentConfirmationComponent
      data={order}
      details={details}
      cancelOrder={async () => await cancelOrder()}
      confirmOrder={confirmOrder}
      isLoading={isLoading}
    />
  )
}

export const PaymentConfirmation = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!location.state) navigate('/recargas')
  }, [location, navigate])

  return (
    <IntegrationSwapper
      isDealer={location?.state?.isDealer}
      components={{
        ifIsMotiva: <OldPaymentConfirmation />,
        ifIsNotMotiva: <NewAPIIntegration />,
      }}
    />
  )
}
