import { useContext, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { Box, Checkbox, Tooltip } from '@stationkim/front-ui'
import { ProjectionCheckBox, SelectedContainer, StyledButton, StyledTypography } from './styles'
import { Option, PaymentConfirmationContext } from '@/presentation/pages/paymentConfirmation/paymentConfirmation'
import { OperatorSelector } from './components/operatorSelector/operatorSelector'

const projectionNotAllowedMessage =
  'Não é possível realizar a projeção de saldo em pedidos que utilizam saldo da conta corrente bilhetagem.'

const getText = (selectedTotal: number) => {
  switch (selectedTotal) {
    case 0:
      return 'Selecionar operadoras'
    case 1:
      return 'Ver operadora selecionada'
    default:
      return 'Ver operadoras selecionadas'
  }
}

export const BalanceProjection = () => {
  const { form, operatorsOptions } = useContext(PaymentConfirmationContext)
  const selectedOperatorsForBalanceProjection = form.watch('selectedOperatorsForBalanceProjection') || []
  const [isOperatorSelectorOpen, setIsOperatorSelectorOpen] = useState(false)

  const notAllowProjection = false

  const buttonText = getText(selectedOperatorsForBalanceProjection.length)
  const handleSelectOperatorClick = () => setIsOperatorSelectorOpen(true)
  const handleSelectorClose = () => setIsOperatorSelectorOpen(false)

  useEffect(() => {
    form.setValue(
      'selectedOperatorsForBalanceProjection',
      operatorsOptions.map((operator) => operator.value),
    )
  }, [form, operatorsOptions])

  if (operatorsOptions.length < 1) return null

  return (
    <>
      <Tooltip title={notAllowProjection ? projectionNotAllowedMessage : ''} placement='top'>
        <ProjectionCheckBox notAllowProjection={notAllowProjection}>
          <Controller
            control={form.control}
            name='selectedOperatorsForBalanceProjection'
            render={({ field: { value, onChange } }) => (
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Checkbox
                  id='recharge_manual_value_projection'
                  checked={value?.length > 0}
                  onChange={() => onChange(value.length > 0 ? [] : operatorsOptions.map((op) => op.value))}
                />
                <b>
                  <label htmlFor='recharge_manual_value_projection'>Quero fazer a projeção de saldo</label>
                </b>
              </Box>
            )}
          />
        </ProjectionCheckBox>
      </Tooltip>
      {operatorsOptions.length > 1 && (
        <SelectedContainer>
          <StyledButton onClick={handleSelectOperatorClick} disableTouchRipple>
            {buttonText}
          </StyledButton>
          {selectedOperatorsForBalanceProjection.length > 0 && (
            <StyledTypography variant='body2'>
              (<b>{selectedOperatorsForBalanceProjection.length}</b> de {operatorsOptions.length})
            </StyledTypography>
          )}
        </SelectedContainer>
      )}
      {isOperatorSelectorOpen && (
        <Controller
          control={form.control}
          name='selectedOperatorsForBalanceProjection'
          render={({ field: { value, onChange } }) => (
            <OperatorSelector
              open={isOperatorSelectorOpen}
              onClose={handleSelectorClose}
              onConfirm={(value) => {
                onChange(value)
                handleSelectorClose()
              }}
              defaultValue={value}
              options={operatorsOptions}
            />
          )}
        />
      )}
    </>
  )
}
