import { useSetAtom } from 'jotai'
import { useMutation } from '@tanstack/react-query'
import { AccountModel, AccountPersistentStore, GlobalThemePersistentStore } from '@/main/store'
import api from '@/infra/api'
import { decode } from 'jsonwebtoken'
import { useLocation } from 'react-router-dom'

export interface ILogin {
  email: string
  password: Storage
  site: string
}

export const useAuth = (navigate?: any) => {
  const setAccountStore = useSetAtom(AccountPersistentStore)
  const setTheme = useSetAtom(GlobalThemePersistentStore)

  const { search } = useLocation()

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload: ILogin) =>
      api.post<AccountModel>(
        `rh-bff/login?${search.includes('isKeycloak=false') ? 'isKeycloak=false' : 'isKeycloak=true'}`,
        payload,
      ),
    onSuccess: (res) => {
      localStorage.setItem('isKeycloak', search.includes('isKeycloak=false') ? 'false' : 'true')
      const account = Object.assign({ ...res.data, company: {} })
      const token = decode(account.auth.accessToken)
      account.company.id = token?.companyId
      const { theme } = account.resale
      setTheme(JSON.parse(theme))
      setAccountStore(account)
    },
  })

  const logout = () => {
    setAccountStore({})
    navigate('/login')
  }

  return {
    login: mutate,
    logout,
    isLoading,
  }
}
