import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Typography,
} from 'presentation/components/atoms'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { useContext } from 'react'
import { CartContext } from 'presentation/pages/cart/cart'

export const CancelCartDialog = ({ open, setOpen }: { open: boolean; setOpen: (isOpen: boolean) => void }) => {
  const {
    cart: { id: cartId },
    cancelCart,
  } = useContext(CartContext)
  return (
    <Dialog open={open} maxWidth='md'>
      <Icon icon={faExclamationTriangle} sx={(theme: any) => ({ color: theme.palette.error.main, height: '42px' })} />
      <DialogTitle
        sx={(theme: any) => ({
          margin: '32px auto',
          color: theme.palette.grey[700],
        })}
      >
        Cancelar carrinho
      </DialogTitle>
      <DialogContent>
        <Typography>
          Ao cancelar este pedido, todas as informações serão perdidas.
          <br />
          Deseja continuar?
        </Typography>
        <DialogActions sx={{ justifyContent: 'space-between', gap: '16px', marginTop: '32px' }}>
          <Button
            id='cart_cancelDialog_cancel_button'
            variant='outlined'
            sx={{ filter: 'grayscale(100%)', flex: 1 }}
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
          <Button
            id='cart_cancelDialog_confirm_button'
            variant='outlined'
            color='error'
            sx={{ flex: 1 }}
            onClick={() => cancelCart.mutate({ cartId })}
          >
            Confirmar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
