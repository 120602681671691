import { useNavigate } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { FieldTableVirtualizedProps, EFieldMasks, formatMoney } from '@stationkim/front-ui'
import api from '@/infra/api'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { downloadString } from '@/main/utils/functions'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { Theme } from '@mui/material'
import { Icon } from '@/presentation/components/atoms'
import { ETipoCampoTabela } from '@/main/enums'

interface APIReturn<T> {
  dataCriacao: string
  id: number
  idEmpresa: string
  idImportacao: string
  idRevenda: string
  idUsuario: string
  nomeArquivo: string
  situacao: number
  valorTotal: number
  tabela: {
    columns: any
    rows: T
  }
}

export const useImportResults = ({
  orderId,
  setEmployeeToAdd,
}: {
  orderId: string
  setEmployeeToAdd?: React.Dispatch<(prevState: undefined) => undefined>
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const notify = useNotifications()
  const brokerImportResultsQueryKey = ['brokerImportResults', orderId]

  const { data, ...rest } = useQuery<AxiosResponse<APIReturn<any>>>({
    queryKey: brokerImportResultsQueryKey,
    queryFn: async () => {
      const response = await api.get<APIReturn<any>>(`rh-bff/brokerapi/importacao/${orderId}`)
      if (response.data.tabela && response.data.id > 0) return response
      else throw new Error('Importação indisponível')
    },
    enabled: Boolean(orderId),
    retry: 10,
    retryDelay: 5000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const { mutate: createOrder, isLoading: createOrderLoading } = useMutation({
    mutationKey: ['createOrderTri', orderId],
    mutationFn: () => api.post(`rh-bff/brokerapi/importacao/gerarpedido`, { IdImportacao: orderId }),
    onSuccess: (res) => {
      navigate('/recargas/pedido_recarga_broker/pagamento', {
        state: { title: 'Recarga', isDealer: false, purchaseId: res?.data?.id },
      })
    },
  })

  const { mutate: getReport, isLoading: getReportLoading } = useMutation({
    mutationKey: ['getBrokerReport', orderId],
    mutationFn: () => api.get(`rh-pedidos-bff/importacao/relatorio-erro/${orderId}`),
    onSuccess: (res) => {
      if (res.data) downloadString(res.data, 'Relatório de erros da importação')
    },
  })

  const { mutate: deleteRow, isLoading: isDeleteRowLoading } = useMutation({
    mutationFn: async (rowId: string) => api.delete(`rh-bff/brokerapi/importacaoItem/${rowId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['brokerImportResults'] })
      notify.push({ content: 'Registro removido', type: EMessageType.Success })
    },
  })

  const { mutate: editRow, isLoading: isEditRowLoading } = useMutation({
    mutationFn: async (payload: any) => {
      const payloadNOError = payload
      payloadNOError.guidImportacao = orderId
      delete payloadNOError.errors
      return api.put(`rh-pedidos-bff/importacao/atualizarItem`, payload)
    },
    onSuccess: (res: any) => {
      queryClient.invalidateQueries({ queryKey: ['brokerImportResults'] })
      notify.push({ content: 'Registro editado', type: EMessageType.Success })
    },
  })

  const organizeData = () => {
    if (!data?.data?.tabela)
      return {
        rows: [],
        employeesCount: 0,
        errorsCount: 0,
        columns: [],
        fileName: '',
      }

    let errorsCount = 0

    const newColumns = [
      ...data?.data?.tabela?.columns.slice(0, 1),
      {
        columnDisplayName: 'Novo Colaborador',
        columnValue: 'novoColaborador',
      },
      ...data?.data?.tabela?.columns.slice(1),
    ]

    const visibleColumns = newColumns.filter((column) => column.displayType !== ETipoCampoTabela.ModalColaborador)

    const columns: FieldTableVirtualizedProps['columns'] = visibleColumns.map((column) => {
      let columnConfig = {
        ...column,
        key: column.columnValue,
        dataKey: column.columnValue,
        title: column.columnDisplayName,
        width: 220,
        editable: column.editable,
        rules: { required: 'Campo obrigatório' },
      }

      if (columnConfig.key === 'novoColaborador') {
        columnConfig = {
          ...columnConfig,
          title: '',
          width: 48,
          minWidth: 48,
          alignment: 'center',
          editable: false,
          resizable: false,
          rules: null,
          dataGetter: ({ rowData }) => {
            return rowData.novoColaborador ? (
              <Icon
                onClick={() => {
                  setEmployeeToAdd(rowData)
                }}
                icon={faUserPlus}
                sx={(theme: Theme) => ({
                  color: theme.palette.warning.main,
                  cursor: 'pointer',
                })}
              />
            ) : null
          },
        }
      }

      return columnConfig
    })
    columns[3].mask = EFieldMasks.Numeric
    columns[4].mask = EFieldMasks.Numeric
    columns[5].mask = EFieldMasks.Numeric
    columns[6].mask = EFieldMasks.Monetary
    columns[7].mask = EFieldMasks.Monetary
    const rows = data?.data?.tabela?.rows.map((row: any) => {
      let hasError = false
      if (row?.erros) {
        hasError = Object.keys(row.erros).some((key) => Boolean(row.erros[key]))
        if (hasError) errorsCount++
      }
      const finalRows = {
        ...row,
        errors: hasError ? row.erros : null,
      }
      delete finalRows.erros
      return finalRows
    })

    return {
      rows,
      errorsCount,
      employeesCount: rows?.length,
      columns,
      fileName: data?.data?.nomeArquivo,
      totalValue: formatMoney(data?.data?.valorTotal),
    }
  }

  const existsNewEmployee = data?.data?.tabela?.rows.every((item) => item.newEmployee)

  return {
    results: organizeData(),
    createOrder,
    createOrderLoading,
    getReport,
    getReportLoading,
    deleteRow,
    editRow,
    existsNewEmployee,
    ...rest,
    isLoading: rest.isFetching || isDeleteRowLoading || isEditRowLoading || createOrderLoading,
  }
}
