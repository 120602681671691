import { styled } from '@mui/material'
import { Box, Button, Typography } from '@stationkim/front-ui'

export const ProjectionCheckBox = styled('div', {
  shouldForwardProp: (propName) => propName !== 'notAllowProjection',
})<{ notAllowProjection?: boolean }>(({ theme, notAllowProjection }) => ({
  border: '1px solid ' + (notAllowProjection ? theme.palette.grey[400] : theme.palette.primary.main),
  borderRadius: '4px',
  padding: '8px 22px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '22px',
  '& label': {
    marginBottom: 0 + '!important',
    color: notAllowProjection ? theme.palette.grey[500] : theme.palette.primary.main,
    fontSize: '1rem',
    width: '100%',
  },
  '& b': {
    margin: 'auto',
    whiteSpace: 'wrap',
  },
  'input, label': {
    cursor: notAllowProjection ? 'not-allowed' : 'pointer',
  },
  '.MuiButtonBase-root': {
    filter: notAllowProjection ? 'grayscale(100)' : '',
    opacity: notAllowProjection ? 0.5 : 1,
  },
}))

export const SelectedContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const StyledButton = styled(Button)(() => ({
  fontWeight: '500',
}))

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  '& b': {
    color: theme.palette.grey[700],
  },
}))
