export const Icon = () => {
  return (
    <svg width='51' height='42' viewBox='0 0 51 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M50.444 36.6341C51.1549 37.3924 51.1549 38.5772 50.444 39.3355C49.6858 40.0464 48.5009 40.0464 47.7427 39.3355L42.1029 33.6484C40.1598 34.928 37.7902 35.5915 35.231 35.2597C30.8709 34.6436 27.3638 31.0892 26.7951 26.7764C25.9894 20.331 31.4396 14.8809 37.885 15.6865C42.1977 16.2553 45.7522 19.7623 46.3683 24.1224C46.7 26.6816 46.0365 29.0513 44.7569 30.947L50.444 36.6341ZM30.4917 25.4494C30.4917 28.8143 33.1931 31.5157 36.558 31.5157C39.8755 31.5157 42.6243 28.8143 42.6243 25.4494C42.6243 22.1319 39.8755 19.3832 36.558 19.3832C33.1931 19.3832 30.4917 22.1319 30.4917 25.4494Z'
        fill='#FF9800'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.18033 10.7594C8.18033 16.3544 12.6721 20.8462 18.2671 20.8462C23.7834 20.8462 28.3539 16.3544 28.3539 10.7594C28.3539 5.24317 23.7834 0.672592 18.2671 0.672592C12.6721 0.672592 8.18033 5.24317 8.18033 10.7594ZM23.7109 24.7105C23.217 24.6565 22.7154 24.6287 22.2073 24.6287H14.2482C6.68307 24.6287 0.615234 30.7754 0.615234 38.3405C0.615234 39.8377 1.79728 41.0198 3.29454 41.0198H33.1609C34.653 41.0198 35.9102 39.846 35.919 38.3561C35.5625 38.3393 35.2025 38.3077 34.8397 38.2606L34.8225 38.2584L34.8053 38.256C29.1033 37.4503 24.5395 32.8352 23.7928 27.172L23.7901 27.1518C23.6865 26.3233 23.6623 25.5073 23.7109 24.7105ZM26.7143 25.3885C26.7087 25.8445 26.7342 26.3078 26.7928 26.7764C27.3615 31.0892 30.8686 34.6436 35.2287 35.2597C35.3461 35.2749 35.4631 35.288 35.5796 35.2991C35.208 33.6621 34.5429 32.1357 33.6428 30.7783C32.4351 30.1202 31.4796 29.0635 30.9494 27.7817C29.7038 26.7488 28.2728 25.9318 26.7143 25.3885Z'
        fill='#FF9800'
      />
    </svg>
  )
}
