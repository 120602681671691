import React from 'react'

import { Radio } from '@mui/material'

import pixIcon from '@/presentation/assets/icons/pix.svg'
import boletoIcon from '@/presentation/assets/icons/boleto-icon.png'
import termBillIcon from '@/presentation/assets/icons/term-bill.svg'
import termBillCheckedIcon from '@/presentation/assets/icons/term-bill-checked.svg'

import { Container } from './styles'

interface MethodPaymentProps {
  name: 'Pix' | 'Boleto' | 'Boleto a Prazo'
  onClick: () => void
  checked: boolean
  blocked?: boolean
  id?: string
}

export const MethodPayment: React.FC<MethodPaymentProps> = ({ name, onClick, checked, blocked, id }) => {
  const icon = {
    Pix: pixIcon,
    Boleto: boletoIcon,
    'Boleto a Prazo': checked ? termBillCheckedIcon : termBillIcon,
  }

  return (
    <Container onClick={onClick} blocked={blocked}>
      <div>
        <img src={icon[name as keyof typeof icon]} alt='paymentIcon' />
        <p>{name}</p>
      </div>
      <Radio checked={checked} id={id} />
    </Container>
  )
}
