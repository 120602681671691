import { useState } from 'react'
import Moment from 'react-moment'

import { Spinner } from 'presentation/components'
import { TableCell, TableRow, TableBody as Body } from 'presentation/components/atoms'
import { DATE_TO_IGNORE, ORDER_STATUS } from '@/utils/constants'
import { getCardOrdersReport, getCardReceipt, getCardSignaturesReport } from '@/services/reportsServices'
import blobDownload from '@/utils/blobDownload'
import blobToJson from '@/utils/blobToJson'
import { getCardsOrderSlip } from '@/services/cardsServices'
import DotMenu from 'presentation/components/dotMenu'
import IconListBenefit from '../IconListBenefit/iconListBenefit'
import { useNotification } from 'main/hooks/useNotification'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

import { Wrapper } from '../../../../styles'
import { useSetAtom } from 'jotai'
import { orderIdAtom } from 'main/store'
import { useNavigate } from 'react-router-dom'

export const TableBody = ({ cards, cancelCardOrderRequest }) => {
  const { resources } = useAccessProfile({ resource: 'cartoes' })

  const [loadingExtraOrderInfo, setLoadingExtraOrderInfo] = useState([])
  const { errorNotification } = useNotification()
  const setOrderId = useSetAtom(orderIdAtom)
  const navigate = useNavigate()
  const viewOrder = (order) => {
    setOrderId(order.id)

    if (order.status === ORDER_STATUS.DRAFT) {
      const isFirst = order.cardCopy === 1

      navigate(`/cartoes/novo/${isFirst ? 'primeira' : 'segunda_confirmacao'}`, {
        state: {
          orderId: order.id,
          bypass: true,
          title: isFirst ? 'Cartão 1ª via' : 'Cartão 2ª via',
          operatorCardName: isFirst ? 'Cartão 1ª via' : 'Cartão 2ª via',
        },
      })
    } else navigate(`/cartoes/acompanhamento/#orderId="${order.id}"`)
  }

  const requestOrderReceipt = async (order) => {
    const { response, error } = await getCardReceipt(order.code)
    if (response) {
      const blob = new Blob([response.data], { type: 'text/plain' })
      const filename = `Recibo de pedido de cartão #${order.code}.pdf`
      blobDownload(blob, filename)
    } else errorNotification({ ...error.response, data: await blobToJson(error.response.data) })
    setLoadingExtraOrderInfo((state) => state.filter((item) => item !== order.id))
  }

  const requestOrderReport = async (order) => {
    const { response, error } = await getCardOrdersReport(order.code)
    if (response) {
      const blob = new Blob([response.data], { type: 'text/plain' })
      const filename = `Relatório de pedido de cartão #${order.code}.pdf`
      blobDownload(blob, filename)
    } else errorNotification({ ...error.response, data: await blobToJson(error.response.data) })
    setLoadingExtraOrderInfo((state) => state.filter((item) => item !== order.id))
  }

  const requestSignaturesList = async (order) => {
    const { response, error } = await getCardSignaturesReport(order.id)
    if (response) {
      const blob = new Blob([response.data], { type: 'text/plain' })
      const filename = `Lista de assinaturas pedido de cartão #${order.code}.pdf`
      blobDownload(blob, filename)
    } else errorNotification({ ...error.response, data: await blobToJson(error.response.data) })
    setLoadingExtraOrderInfo((state) => state.filter((item) => item !== order.id))
  }

  const requestOrderSlip = async (orderId) => {
    const { error, response } = await getCardsOrderSlip(orderId)
    if (response) {
      window.open(response.data, '_blank').focus()
    } else errorNotification(error.response)
    setLoadingExtraOrderInfo((state) => state.filter((item) => item !== orderId))
  }

  if (!cards?.length) return

  return (
    <Body>
      {cards.map((order, index) => (
        <TableRow key={`card_order_${index}`} onClick={() => viewOrder(order)}>
          <TableCell>
            {order.orderDate && !order.orderDate.includes(DATE_TO_IGNORE) && (
              <Moment format='DD/MM/YYYY' date={order.orderDate} />
            )}
          </TableCell>
          <TableCell>{order.code}</TableCell>
          <TableCell>{order.cardsQuantity}</TableCell>
          <TableCell style={{ width: '188px', padding: '0 48px 0 0' }}>
            <IconListBenefit itemsList={order.benefits?.length > 0 ? order?.benefits : []} />
          </TableCell>
          <TableCell>
            <Wrapper>{ORDER_STATUS[order.status]}</Wrapper>
          </TableCell>
          <TableCell style={{ textAlign: 'center' }} onClick={(e) => e.stopPropagation()}>
            {loadingExtraOrderInfo.includes(order.id) ? (
              <Spinner variant='sm' />
            ) : [
                ORDER_STATUS.DRAFT,
                ORDER_STATUS.WAITING_PAYMENT,
                ORDER_STATUS.PROCESSING,
                ORDER_STATUS.FINISHED,
              ].includes(order.status) ? (
              <DotMenu>
                {order.status === ORDER_STATUS.WAITING_PAYMENT && (
                  <DotMenu.Option
                    onClick={() => {
                      requestOrderSlip(order.id)
                      setLoadingExtraOrderInfo((state) => [...state, order.id])
                    }}
                  >
                    Boleto
                  </DotMenu.Option>
                )}
                {order.status === ORDER_STATUS.FINISHED && (
                  <>
                    {resources({ item: 'cartoes_pedido_relatorio' }) ? (
                      <DotMenu.Option
                        onClick={() => {
                          requestOrderReceipt(order)
                          setLoadingExtraOrderInfo((state) => [...state, order.id])
                        }}
                      >
                        Recibo
                      </DotMenu.Option>
                    ) : null}
                    {resources({ item: 'cartoes_pedido_relatorio' }) ? (
                      <DotMenu.Option
                        onClick={() => {
                          requestSignaturesList(order)
                          setLoadingExtraOrderInfo((state) => [...state, order.id])
                        }}
                      >
                        Lista de assinaturas
                      </DotMenu.Option>
                    ) : null}
                    {resources({ item: 'cartoes_pedido_relatorio' }) ? (
                      <DotMenu.Option
                        onClick={() => {
                          requestOrderReport(order)
                          setLoadingExtraOrderInfo((state) => [...state, order.id])
                        }}
                      >
                        Baixar Relatório
                      </DotMenu.Option>
                    ) : null}
                  </>
                )}

                {[ORDER_STATUS.DRAFT, ORDER_STATUS.WAITING_PAYMENT].includes(order.status) &&
                  (resources({ item: 'cartoes_pedido_cancelar' }) ? (
                    <DotMenu.Option
                      onClick={() =>
                        cancelCardOrderRequest({
                          order: {
                            id: order.id,
                            cartId: order.basketId,
                            code: order.code,
                            orderDate: order.orderDate,
                            status: order.status,
                            orderType: order.orderType,
                            totalValue: order.totalValue,
                            totalItems: order.cardsQuantity,
                          },
                        })
                      }
                    >
                      Cancelar Pedido
                    </DotMenu.Option>
                  ) : null)}
              </DotMenu>
            ) : null}
          </TableCell>
        </TableRow>
      ))}
    </Body>
  )
}
