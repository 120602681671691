import React, { useEffect, useRef, useState } from 'react'
import { Background, PageHeader, Content, Panel, Table, Spinner, Button, CompositeDate } from 'presentation/components'
import {
  Body,
  Subtitle,
  Balance,
  BalanceTableFooter,
  BalanceVisibilitySelector,
  CustomTd,
  CustomTr,
  DateWrapper,
  LoadingWrapper,
  NoRegister,
  OrderSelector,
  StatementIcon,
  Value,
  BalanceValue,
  CustomTable,
  CustomTableWrapper,
  SlipLink,
  Separator,
  FilterButton,
  ButtonWrapper,
} from './styles'
import { BiCoinStack } from 'react-icons/bi'
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { getCompanyStatement, getDepositOrders, postDepositBalance } from '@/services/financialServices'
import useService from 'main/hooks/useService'
import { formatMoney, sendToTransferArea, sumDaysToDate } from '@/utils/functionUtils'
import Moment from 'react-moment'
import 'moment/locale/pt-br'
import { Col, Container, Hidden, Row, useScreenClass } from 'react-grid-system'
import { useDispatch, useSelector } from 'react-redux'
import { setGlobalState } from '@/store/modules/globalState/actions'
import GenerateSlipModal from './generateSlipModal'
import SlipModal from './slipModal'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import { ORDER_STATUS } from '@/utils/constants'
import DotMenu from 'presentation/components/dotMenu'
import successHandler from '@/utils/successHandler'
import _ from 'lodash'
import moment from 'moment'
import useDownloadReport from 'main/hooks/useDownloadReport'
import { getStatementReport } from '@/services/reportsServices'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

const Financial = () => {
  const { resources } = useAccessProfile({ resource: 'financeiro' })

  const today = new Date()
  const [statementState, statementServiceRequest] = useService(getCompanyStatement)
  const [getDepositSlipsState, getDepositSlipsServiceRequest] = useService(getDepositOrders)
  const [getStatementReportState, getStatementReportRequest] = useDownloadReport(getStatementReport)
  const statementInfo = statementState.response?.data
  const slipsInfo = getDepositSlipsState.response?.data
  const [isAscending, setIsAscending] = useState(false)
  const [filterButtonsDays, setFilterButtonsDays] = useState(1)
  const [filterButtonsType, setFilterButtonsType] = useState(0)
  const [isGenerateSlipModalVisible, setIsGenerateSlipModalVisible] = useState(false)
  const [loading, setLoading] = useState()
  const [slipInfo, setSlipInfo] = useState(null)
  const [dates, setDates] = useState([sumDaysToDate(today, -14), today])
  const { motivaCashBalanceVisibility } = useSelector((state) => state.globalState)
  const lastQuery = useRef(null)

  const dispatch = useDispatch()
  const screenClass = useScreenClass()

  useEffect(() => {
    let query = ''
    if (dates[0] && dates[1])
      query += `&date=${moment(dates[0]).format('YYYY-MM-DD')}><${moment(dates[1]).format('YYYY-MM-DD')}`
    if (filterButtonsType !== 0) query += `&type=${filterButtonsType}`
    query = query.replace('&', '?')
    query !== lastQuery.current && statementServiceRequest(query)
    lastQuery.current = query
    // eslint-disable-next-line
  }, [filterButtonsDays, filterButtonsType, dates])

  // eslint-disable-next-line
  useEffect(() => {
    getDepositSlipsServiceRequest('?Sort=OrderDate&Desc=true&PageSize=3')
  }, [])

  const groupByDate = (itemsToGroup, getDateToCompare = () => null) => {
    const groups = [[]]
    let lastDate = { group: 0, date: '' }
    itemsToGroup.forEach((itemToGroup) => {
      const date = getDateToCompare(itemToGroup).slice(0, 10)
      if (lastDate.date === date || lastDate.date === '') {
        groups[lastDate.group].push(itemToGroup)
        lastDate.date = date
      } else {
        for (let i = 0; i < groups.length; i++) {
          if (getDateToCompare(groups[i][0]).includes(date)) {
            lastDate = { group: i, date: date }
            groups[i].push(itemToGroup)
            break
          } else if (i === groups.length - 1) {
            lastDate = { group: i + 1, date: date }
            groups.push([itemToGroup])
            break
          }
        }
      }
    })
    return groups
  }

  const generateStatementTableRows = () => {
    const groupedTransactions = !isAscending
      ? groupByDate(statementInfo?.transactions, (transaction) => transaction.date)
      : groupByDate(statementInfo?.transactions, (transaction) => transaction.date)
          .slice(0)
          .reverse()

    return groupedTransactions.map((group) => {
      return group.map((transaction, index) => (
        <CustomTr underlined={index === group.length - 1} key={_.uniqueId()}>
          {index === 0 && (
            <CustomTd rowSpan={group.length} header>
              <DateWrapper>
                <Moment locale='pt-BR' format='ddd' filter={(date) => date.charAt(0).toUpperCase() + date.slice(1, 4)}>
                  {transaction.date}
                </Moment>
                <br />
                <Moment locale='pt-BR' format='D MMM' filter={(date) => date.toUpperCase()}>
                  {transaction.date}
                </Moment>
              </DateWrapper>
            </CustomTd>
          )}
          <CustomTd>{transaction.description}</CustomTd>
          <CustomTd>
            <Value value={transaction.value}>R$ {formatMoney(transaction.value)}</Value>
          </CustomTd>
        </CustomTr>
      ))
    })
  }

  const generateSlipsTableRows = () => {
    const groupedTransactions = groupByDate(slipsInfo?.data, (slip) => slip.issueDate)
    return groupedTransactions.map((group) => {
      return group.map((slip, index) => (
        <CustomTr underLined={index === group.length - 1} key={_.uniqueId()}>
          {index === 0 && (
            <CustomTd rowSpan={group.length}>
              <DateWrapper>
                <Moment locale='pt-BR' format='ddd' filter={(date) => date.charAt(0).toUpperCase() + date.slice(1, 4)}>
                  {slip.issueDate}
                </Moment>
                <br />
                <Moment locale='pt-BR' format='D MMM' filter={(date) => date.toUpperCase()}>
                  {slip.issueDate}
                </Moment>
              </DateWrapper>
            </CustomTd>
          )}
          <CustomTd>Depósito por Boleto</CustomTd>
          <CustomTd>
            <Moment format='DD/MM/YYYY'>{slip.dueDate}</Moment>
          </CustomTd>
          <CustomTd>{ORDER_STATUS[slip.status]}</CustomTd>
          <CustomTd>
            <Value value={slip.totalValue}>R$ {formatMoney(slip.totalValue)}</Value>
          </CustomTd>
          <CustomTd>
            {slip.status !== ORDER_STATUS.COMPLETED ? (
              <DotMenu>
                <DotMenu.Option>
                  <SlipLink href={slip.slipLink} target='_blank' rel='noreferrer'>
                    Baixar boleto
                  </SlipLink>
                </DotMenu.Option>
                <DotMenu.Option
                  onClick={() => {
                    sendToTransferArea(slip.digitableLine)
                    dispatch(pushNotification(successHandler('Código copiado!')))
                  }}
                >
                  Copiar código do boleto
                </DotMenu.Option>
              </DotMenu>
            ) : null}
          </CustomTd>
        </CustomTr>
      ))
    })
  }

  const generateSlip = async (payload) => {
    setLoading(true)
    const { error, response } = await postDepositBalance(payload)
    setLoading(false)
    if (response) {
      setSlipInfo(response.data)
      setIsGenerateSlipModalVisible(false)
    } else dispatch(pushNotification(errorHandler(error.response)))
  }

  return (
    <>
      <Content>
        <Panel noPadding>
          <PageHeader>Financeiro</PageHeader>
          <Body>
            <>
              <Container fluid style={{ padding: 0 }}>
                <Row gutterWidth={0} align='center' style={{ marginBottom: '16px' }}>
                  <Col sm='content'>
                    <StatementIcon>
                      <BiCoinStack />
                    </StatementIcon>
                  </Col>

                  <Col sm='content'>
                    <Subtitle>Extrato Financeiro</Subtitle>
                  </Col>
                </Row>
                <Row justify='end'>
                  {resources({ item: 'financeiro_depositar' }) ? (
                    <Col md={4} lg={3} xl={2}>
                      <ButtonWrapper>
                        <Button icon='boleto' variant='action' onClick={() => setIsGenerateSlipModalVisible(true)}>
                          Depositar
                        </Button>
                      </ButtonWrapper>
                    </Col>
                  ) : null}
                  <Col md={4} lg={3} xl={2.5}>
                    <ButtonWrapper>
                      {getStatementReportState.loading ? (
                        <Spinner variant='sm' />
                      ) : (
                        <Button
                          icon='download'
                          variant='action outlined'
                          onClick={() => getStatementReportRequest('Extrato financeiro.pdf', lastQuery.current)}
                        >
                          Baixar Extrato
                        </Button>
                      )}
                    </ButtonWrapper>
                  </Col>
                </Row>
                <Row align='center' justify={['xs', 'sm', 'md'].includes(screenClass) ? 'center' : 'start'}>
                  <Col lg={12} xl='content'>
                    <CompositeDate
                      value={dates}
                      limitByMonth={6}
                      onChange={(e) => {
                        setDates(e)
                        setFilterButtonsDays(0)
                      }}
                    />
                  </Col>
                  <Col xs='content' lg='content'>
                    <FilterButton
                      active={filterButtonsDays === 1}
                      onClick={() => {
                        if (filterButtonsDays === 1) return
                        setFilterButtonsDays(1)
                        setDates([sumDaysToDate(today, -14), today])
                      }}
                    >
                      15 Dias
                    </FilterButton>

                    <FilterButton
                      active={filterButtonsDays === 2}
                      onClick={() => {
                        if (filterButtonsDays === 2) return
                        setFilterButtonsDays(2)
                        setDates([sumDaysToDate(today, -29), today])
                      }}
                    >
                      30 Dias
                    </FilterButton>

                    <FilterButton
                      active={filterButtonsDays === 3}
                      onClick={() => {
                        if (filterButtonsDays === 3) return
                        setFilterButtonsDays(3)
                        setDates([sumDaysToDate(today, -59), today])
                      }}
                    >
                      60 Dias
                    </FilterButton>
                  </Col>
                  <Hidden xs sm md>
                    <Col sm='content'>
                      <Separator />
                    </Col>
                  </Hidden>
                  <Col xs='content'>
                    <FilterButton
                      active={filterButtonsType === 1}
                      onClick={() => filterButtonsType !== 1 && setFilterButtonsType(1)}
                    >
                      Entradas
                    </FilterButton>

                    <FilterButton
                      active={filterButtonsType === 2}
                      onClick={() => filterButtonsType !== 2 && setFilterButtonsType(2)}
                    >
                      Saidas
                    </FilterButton>

                    <FilterButton
                      active={filterButtonsType === 0}
                      onClick={() => filterButtonsType !== 0 && setFilterButtonsType(0)}
                    >
                      Todas
                    </FilterButton>
                  </Col>
                </Row>

                <Row justify='end'>
                  <Col sm='content'>
                    <Balance>
                      Saldo Atual:
                      <BalanceValue visible={motivaCashBalanceVisibility}>
                        {motivaCashBalanceVisibility && formatMoney(statementInfo?.balance)}
                      </BalanceValue>
                      <BalanceVisibilitySelector>
                        {!motivaCashBalanceVisibility ? (
                          <AiOutlineEye
                            onClick={() =>
                              dispatch(setGlobalState('motivaCashBalanceVisibility', !motivaCashBalanceVisibility))
                            }
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            onClick={() =>
                              dispatch(setGlobalState('motivaCashBalanceVisibility', !motivaCashBalanceVisibility))
                            }
                          />
                        )}
                      </BalanceVisibilitySelector>
                    </Balance>
                  </Col>
                </Row>
              </Container>
              {statementState.loading ? (
                <LoadingWrapper>
                  <Spinner />
                </LoadingWrapper>
              ) : (
                <CustomTableWrapper>
                  <CustomTable>
                    <thead>
                      <CustomTr>
                        <Table.Th onClick={() => setIsAscending((state) => !state)} style={{ cursor: 'pointer' }}>
                          Data
                          <OrderSelector>
                            <RiArrowUpSFill onClick={() => setIsAscending(true)} />
                            <RiArrowDownSFill onClick={() => setIsAscending(false)} />
                          </OrderSelector>
                        </Table.Th>
                        <Table.Th>Tipo de Movimentação</Table.Th>
                        <Table.Th>Valor</Table.Th>
                      </CustomTr>
                    </thead>
                    <tbody>
                      {statementInfo?.transactions.length ? (
                        <>{generateStatementTableRows()}</>
                      ) : (
                        <CustomTr>
                          <CustomTd colSpan='3'>
                            <NoRegister>Sem registros para exibir</NoRegister>
                          </CustomTd>
                        </CustomTr>
                      )}
                      <CustomTr colored>
                        <CustomTd colSpan='3'>
                          <BalanceTableFooter>
                            Saldo Atual:
                            <BalanceValue visible={motivaCashBalanceVisibility}>
                              {motivaCashBalanceVisibility && formatMoney(statementInfo?.balance)}
                            </BalanceValue>
                            <BalanceVisibilitySelector>
                              {!motivaCashBalanceVisibility ? (
                                <AiOutlineEye
                                  onClick={() =>
                                    dispatch(
                                      setGlobalState('motivaCashBalanceVisibility', !motivaCashBalanceVisibility),
                                    )
                                  }
                                />
                              ) : (
                                <AiOutlineEyeInvisible
                                  onClick={() =>
                                    dispatch(
                                      setGlobalState('motivaCashBalanceVisibility', !motivaCashBalanceVisibility),
                                    )
                                  }
                                />
                              )}
                            </BalanceVisibilitySelector>
                          </BalanceTableFooter>
                        </CustomTd>
                      </CustomTr>
                    </tbody>
                  </CustomTable>
                </CustomTableWrapper>
              )}
              <Container fluid style={{ padding: 0 }}>
                <Row>
                  <Col>
                    <Subtitle withMargin>Boleto Gerados Para Depósitos</Subtitle>
                  </Col>
                </Row>
              </Container>
              {getDepositSlipsState.loading ? (
                <LoadingWrapper>
                  <Spinner />
                </LoadingWrapper>
              ) : (
                <CustomTableWrapper>
                  <CustomTable>
                    <thead>
                      <CustomTr>
                        <Table.Th>Data</Table.Th>
                        <Table.Th>Tipo de Movimentação</Table.Th>
                        <Table.Th>Vencimento do Boleto</Table.Th>
                        <Table.Th>Status</Table.Th>
                        <Table.Th>Valor</Table.Th>
                      </CustomTr>
                    </thead>
                    <tbody>
                      {slipsInfo?.data.length ? (
                        <>{generateSlipsTableRows()}</>
                      ) : (
                        <CustomTr>
                          <CustomTd colSpan='5'>
                            <NoRegister>Sem registros para exibir</NoRegister>
                          </CustomTd>
                        </CustomTr>
                      )}
                    </tbody>
                  </CustomTable>
                </CustomTableWrapper>
              )}
            </>
          </Body>
        </Panel>
      </Content>
      {isGenerateSlipModalVisible && (
        <GenerateSlipModal
          open={isGenerateSlipModalVisible}
          loading={loading}
          onConfirm={generateSlip}
          onClose={() => setIsGenerateSlipModalVisible(false)}
        />
      )}
      {!!slipInfo && (
        <SlipModal
          open={!!slipInfo}
          slipInfo={slipInfo}
          onClose={() => {
            setSlipInfo(false)
            getDepositSlipsServiceRequest('?Sort=OrderDate&Desc=true&PageSize=3')
          }}
        />
      )}
    </>
  )
}

export default Financial
