import { createContext } from 'react'
import { useLocation } from 'react-router-dom'
import { UseFormReturn, useForm } from 'react-hook-form'
import { Divider, Grid, Paper } from 'presentation/components/atoms'
import { Content } from 'presentation/components'
import { CartSkeleton, Details, NoItems } from 'presentation/components/orders'
import { PageHeader } from 'presentation/components/molecules'
import { Payment } from './components/payment/payment'
import { UseMutationResult } from '@tanstack/react-query'
import { IConfirmOrder } from './hooks/useOrder'
import { ETipoPagamento } from '@/main/enums'

//mover para lib
export type Option = {
  label: string | number
  value: string | number
}

interface IPaymentFormValues {
  paymentMethod: ETipoPagamento
  dayForCredit: any
  walletUsage: number
  balanceProjection: boolean
  selectedOperatorsForBalanceProjection: Array<string | number>
}
interface IPaymentConfirmationContext {
  form: UseFormReturn<IPaymentFormValues>
  walletBalance: number
  operatorsOptions?: Option[]
  confirmOrder: UseMutationResult<unknown, unknown, IConfirmOrder, unknown>
  order: {
    detailedValues: {
      administrativeFees?: number | null
      transferFee?: number | null
      issFee?: number | null
      ticketingBalanceUsage?: number | null
      orderValue?: number | null
      totalBeforeDynamicValues?: number | null
    }
  }
}

export const PaymentConfirmationContext = createContext<IPaymentConfirmationContext>({} as IPaymentConfirmationContext)

export const PaymentConfirmation = ({ data, details, isLoading, cancelOrder, confirmOrder }) => {
  const location = useLocation()

  const orderId = location.state?.orderId

  const form = useForm<IPaymentFormValues>({
    mode: 'onChange',
    defaultValues: {
      paymentMethod: ETipoPagamento.Boleto,
      dayForCredit: '',
      walletUsage: undefined,
      selectedOperatorsForBalanceProjection: [],
    },
  })

  const isEmpty = location.state?.quantity <= 0

  return (
    <PaymentConfirmationContext.Provider
      value={{
        form,
        walletBalance: data?.accountBalance,
        order: {
          detailedValues: {
            administrativeFees: data?.administrateFee,
            issFee: data?.issFeeValue,
            ticketingBalanceUsage: data?.ticketingBalanceValue,
            transferFee: data?.transferFeeValue,
            orderValue: data?.orderValue,
            totalBeforeDynamicValues: data?.totalBeforeBalanceUsage,
          },
        },
        operatorsOptions: data?.operatorsForBalanceProjection,
        confirmOrder,
      }}
    >
      <Content>
        <Paper sx={{ padding: '32px' }}>
          <Grid container justifyContent='space-between' spacing={2}>
            <Grid item xs={12}>
              <PageHeader pageTitle='Comprar agora' noDivider padding='0' />
            </Grid>
          </Grid>
          <Divider sx={{ margin: '32px 0' }} />
          {isLoading ? (
            <CartSkeleton />
          ) : (
            <Grid container columnSpacing='32px' rowSpacing='32px'>
              <Grid item xs={12} md={7}>
                {isEmpty ? (
                  <NoItems />
                ) : (
                  <Details
                    total={data.totalBeforeBalanceUsage}
                    title={location.state?.title}
                    items={details}
                    operatorCardName={location.state?.operatorCardName}
                    cancelOrder={() => cancelOrder({ orderId })}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={5}>
                <Payment />
              </Grid>
            </Grid>
          )}
        </Paper>
      </Content>
    </PaymentConfirmationContext.Provider>
  )
}
