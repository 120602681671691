import api from '@/infra/api'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useCreateEmployee = ({ orderId, onClose }: { orderId: string; onClose: () => void }) => {
  const notifications = useNotifications()
  const queryClient = useQueryClient()

  const createEmployee = useMutation({
    mutationKey: ['createEmployeeAtOrder'],
    mutationFn: async (payload: any) => {
      const payloadNOError = payload
      payloadNOError.guidImportacao = orderId
      delete payloadNOError.errors
      return api.put(`rh-pedidos-bff/importacao/atualizarItem`, payload)
    },
    onSuccess: (res: any) => {
      queryClient.invalidateQueries({ queryKey: ['brokerImportResults'] })
      notifications.push({ content: 'Dados editados com sucesso!', type: EMessageType.Success })
      onClose()
    },
  })

  return { createEmployee }
}
