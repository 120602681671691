import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@stationkim/front-ui'

import { Icon } from './assets/Icon'

import { Actions, MainIcon, Title } from './styles'

interface ConfirmDialogProps {
  onClose?: () => void
  open: boolean
  onConfirm?: () => void
}

export const ConfirmDialog = ({ onClose, open, onConfirm }: ConfirmDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm'>
      <MainIcon>
        <Icon />
      </MainIcon>
      <Title>
        Existem colaboradores não cadastrados <Icon />
      </Title>
      <DialogContent sx={{ margin: '16px 0 64px 0' }}>
        <Typography variant='body2' sx={{ margin: '16px 0 16px 0' }}>
          Alguns colaboradores presentes na planilha ainda não estão cadastrados na plataforma. Preencha a lista com as
          informações faltantes, ou ignore esta etapa:{' '}
          <b>ao ignorar os colaboradores não cadastrados serão descartados, e a revenda não será sinalizada.</b>
        </Typography>
      </DialogContent>
      <Actions>
        <Button variant='outlined' fullWidth onClick={() => onConfirm()}>
          Continuar sem cadastro
        </Button>
        <Button variant='outlined' fullWidth type='submit' form='newEmployee' onClick={onClose}>
          Cadastrar colaboradores
        </Button>
      </Actions>
    </Dialog>
  )
}
