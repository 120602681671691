import { formatMoney } from '@/utils/functionUtils'
import { Container, Radio } from './styles'
import { formatDate } from './utils/date'

interface RateProps {
  day: number
  date: string
  onClick: () => void
  checked: boolean
  totalValue: number
  id?: string
}

export const Rate: React.FC<RateProps> = ({ day, onClick, checked, date, totalValue, id }) => {
  return (
    <Container onClick={onClick} checked={checked}>
      <Radio checked={checked} id={id} />
      <div>
        {date ? <small>Para {formatDate({ date })}</small> : null}
        <p>{day} dias</p>
        <small>Total R$ {formatMoney(totalValue)}</small>
      </div>
    </Container>
  )
}
