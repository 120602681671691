import { styled } from '@mui/material'
import { Box } from '@stationkim/front-ui'

export const CheckableCardContainer = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'checked',
})<{ checked?: boolean }>(({ theme, checked }) => ({
  border: '1px solid ' + theme.palette.primary.main,
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: checked ? theme.palette.primary.light + '1D' : 'initial',
  padding: '16px',
  '& p': {
    fontWeight: 700,
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}))
