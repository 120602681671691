import { createContext, useState } from 'react'
import { Divider, Grid, Paper } from 'presentation/components/atoms'
import { Content } from 'presentation/components'
import { Payment } from './components/payment/payment'
import { useCart } from './hooks/useCart'
import { Details } from './components/details/details'
import { NoItems } from './components/noItems/noItems'
import { PageHeader } from 'presentation/components/molecules'
import { CartSkeleton } from './components/cartSkeleton/cartSkeleton'
import { useRemoveCartItem } from './hooks/useRemoveCartItem'
import { useCancelCart } from './hooks/useCancelCart'
import { useConfirmCart } from './hooks/useConfirmCart'
import { useNotification } from 'main/hooks/useNotification'
import { useNavigate } from 'react-router-dom'

export const CartContext = createContext()

export const Cart = () => {
  const [method, setMethod] = useState('SLIP')
  const [rate, setRate] = useState({
    interestRateDays: 0,
    interestRate: 0,
    nextDueDate: '',
    rateValue: 0,
    totalValueWithInterestRate: 0,
  })
  const navigate = useNavigate()
  const { successNotification } = useNotification()
  const cartHook = useCart()
  const { removeItem } = useRemoveCartItem()
  const { confirmCart } = useConfirmCart({
    onSuccess: () =>
      navigate('/carrinho/confirmacao', {
        state: {
          orderId: cart.id,
          orderType: method,
        },
      }),
  })
  const { cancelCart } = useCancelCart({
    onSuccess: () => {
      successNotification('Carrinho cancelado!')
    },
  })

  const [balanceToBeUsed, setBalanceToBeUsed] = useState(0)
  const { cart, isFetching: isLoadingCart } = cartHook

  const totalBeforeBalanceUsage =
    cart.rechargeValue +
    cart.firstCopyValue +
    cart.secondCopyValue +
    cart.issFeeCashBack +
    cart.administrateFee +
    cart.transferFeeValue -
    cart.issFeeValue -
    cart.ticketingBalanceValue

  const isLoading = isLoadingCart || removeItem.isLoading || cancelCart.isLoading || confirmCart.isLoading
  const totalItems = cart.rechargeOrders?.length + cart.firstCopyOrders?.length + cart.secondCopyOrders?.length || 0
  const isCartEmpty = totalItems <= 0

  return (
    <Content>
      <CartContext.Provider
        value={{
          ...cartHook,
          removeItem,
          cancelCart,
          confirmCart,
          isCartEmpty,
          totalBeforeBalanceUsage: parseFloat(totalBeforeBalanceUsage.toFixed(2)),
          balanceToBeUsed,
          setBalanceToBeUsed,
          setMethod,
          method,
          setRate,
          rate,
        }}
      >
        <Paper sx={{ padding: '32px' }}>
          <Grid container justifyContent='space-between' spacing={2}>
            <Grid item xs={4}>
              <PageHeader pageTitle='Carrinho' noDivider padding='0' id='cart_title' />
            </Grid>
          </Grid>
          <Divider sx={{ margin: '32px 0' }} />
          {isLoading ? (
            <CartSkeleton />
          ) : (
            <Grid container columnSpacing='32px' rowSpacing='32px'>
              <Grid item xs={12} md={7}>
                {isCartEmpty ? <NoItems /> : <Details cart={cart} totalItems={totalItems} />}
              </Grid>
              <Grid item xs={12} md={5}>
                <Payment />
              </Grid>
            </Grid>
          )}
        </Paper>
      </CartContext.Provider>
    </Content>
  )
}
