import { ORDER_TYPES } from 'main/utils/constants'
import { ReactComponent as CardIcon } from 'presentation/assets/icons/card.svg'
import { ReactComponent as RechargeIcon } from 'presentation/assets/icons/recharge.svg'

export interface IOrder extends Record<string, any> {
  __orderCode: number
  __cardName: string
  __totalValue: number
  __orderType: number
  __items: number
  __status: number
  __account: number
  __rechargeValue: number
  __administrativeFee: number
  __passFee: number
  __issFee: number
  isFinancedSales: boolean
}

export const orderTypeVariations = ({
  orderType,
  isFinancedSales,
}: {
  orderType: number
  isFinancedSales: boolean
}) => {
  const variationToBeUsed = () => {
    if (isFinancedSales) return 'financed'
    if (orderType > 2) return 'recharge'

    return 'card'
  }
  const cartTypeTitle = ORDER_TYPES.FIRST_COPY === orderType ? '1° Via' : '2° Via'

  const variation = {
    financed: {
      status: ['Efetivando', 'Pedido Enviado', 'Boleto a prazo pendente', 'Concluído'],
      type: 'Recarga',
      generateItemsString: (value: number) => `${value} ${value > 1 ? 'itens' : 'item'}`,
      icon: RechargeIcon,
    },
    recharge: {
      status: ['Efetivando', 'Aguardando Pagamento', 'Aguardando Processamento', 'Concluído'],
      type: 'Recarga',
      generateItemsString: (value: number) => `${value} ${value > 1 ? 'itens' : 'item'}`,
      icon: RechargeIcon,
    },
    card: {
      status: ['Efetivando', 'Aguardando Pagamento', 'Processamento Operadora', 'Disponível para retirada'],
      type: `Cartão - ${cartTypeTitle}`,
      generateItemsString: (value: number) => `Colab. no pedido: ${value}`,
      icon: CardIcon,
    },
  }
  return variation[variationToBeUsed()]
}
