import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import filesize from 'filesize'
import api from '@/infra/api'
import ImportResult from './components/importResults'
import { ExplanationText, ProgressPosition, ContentButtons, ContentDropzone } from './style'
import { Content, Panel, Dropzone, Spacing, Modal, PageHeader } from 'presentation/components'
import { Button } from 'presentation/components/atoms'
import axios from 'axios'
import { TEN_MB } from '@/utils/constants'
import { download } from '@/utils/functionUtils'

const ImportRecharge = () => {
  const [uploadingFile, setUploadingFile] = useState({})
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)

  const location = useLocation()

  const operatorCardId = location.state?.operatorCardId

  function processUpload(file) {
    const data = new FormData()
    data.append('files', file.file)
    try {
      api
        .post(`/orders-service/Recharges/Import/${operatorCardId}`, data, {
          cancelToken: uploadingFile.cancelToken.token,
          onUploadProgress: (e) => {
            const progress = parseInt(Math.round((e.loaded * 100) / e.total))
            setUploadingFile({
              ...uploadingFile,
              percentage: progress,
              uploading: true,
              send: false,
            })
          },
        })
        .then((response) => {
          setUploadingFile({
            ...uploadingFile,
            uploaded: true,
            uploading: false,
            send: false,
          })
          setResponse(response.data)
        })
        .catch((e) => {
          setUploadingFile({
            ...uploadingFile,
            error: true,
            uploading: false,
            send: false,
          })
          setResponse(e.message === 'cancel' ? null : e.response.data)
        })
    } catch (error) {
      return
    }
  }

  useEffect(() => {
    uploadingFile.send && processUpload(uploadingFile)
    // eslint-disable-next-line
  }, [uploadingFile])

  function handleDrop(file) {
    if (file.length < 1) return
    const cancel = axios.CancelToken.source()
    setUploadingFile({
      file: file[0],
      name: file[0].name,
      readeableSize: filesize(file[0].size),
      percentage: 0,
      uploaded: false,
      cancelToken: cancel,
      error: false,
      send: true,
    })
    setResponse(null)
  }

  const downloadFileTxt = async () => {
    const response = await api.get(
      `${process.env.REACT_APP_API_HOST}/fileuploads-service/files?Key=model_import_recharge_deiler&Format=txt`,
    )
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
    download(fileUrl, 'Modelo de arquivo para importação de colaboradores.txt')
  }

  const downloadFileExcel = async () => {
    return window.open('https://kim-mais-repos-hml.stationkim.com/modelos/modelo_de_arquivo_pedido_recarga.xls')
  }

  return (
    <>
      <Content>
        <Panel style={{ minHeight: '700px' }} noPadding>
          <PageHeader>Pedido de Recarga por Importação</PageHeader>
          {!uploadingFile.uploading && !response && (
            <>
              <ExplanationText>
                Solicite uma recarga através do arquivo de importação. Baixe o nosso modelo de arquivo, preencha com as
                informações solicitadas respeitando as linhas e campos. Em seguida salve o arquivo e faça a importação.
              </ExplanationText>
              <Spacing top='32px' />
              <ContentButtons>
                <Button
                  variant='contained'
                  startIcon={<FontAwesomeIcon icon={faDownload} />}
                  style={{ width: '250px', marginRight: 20 }}
                  onClick={downloadFileTxt}
                >
                  Baixar modelo .TXT
                </Button>
                <Button
                  variant='contained'
                  startIcon={<FontAwesomeIcon icon={faDownload} />}
                  style={{ width: '250px' }}
                  onClick={downloadFileExcel}
                >
                  Baixar modelo Excel
                </Button>
              </ContentButtons>
              <Spacing top='32px' />
              <ContentDropzone>
                <Dropzone
                  accept={['.txt', '.xls']}
                  onDrop={handleDrop}
                  formatsLabel='txt/xls'
                  multiple={false}
                  maxSize={TEN_MB}
                  setError={setError}
                />
              </ContentDropzone>
            </>
          )}
          {uploadingFile?.uploading && !response && (
            <ProgressPosition>
              <Dropzone.Progress
                uploadingFile={uploadingFile}
                onCancel={() => uploadingFile?.cancelToken?.cancel('cancel')}
              />
            </ProgressPosition>
          )}
          {!!response && <ImportResult response={response} onDrop={handleDrop} />}
        </Panel>
      </Content>
      {error && (
        <Modal onlyConfirm open={error} confirm={() => setError(null)}>
          {error}
        </Modal>
      )}
    </>
  )
}

export default ImportRecharge
