export const breadcrumbNameMap: { [key: string]: string } = {
  '/cartoes': 'Cartões',
  '/recargas/novo_pedido_importacao': 'Novo pedido por importação',
  '/recargas/novo_pedido_manual': 'Novo pedido manual',
  '/recargas/novo_pedido_manual_valores': 'Novo pedido manual',
  '/recargas/novo_pedido_manual_cartoes': 'Novo pedido manual',
  '/recargas/novo_pedido_importacao_cartoes': 'Novo Pedido por Importação',
  '/recargas/pedido_recarga_broker': 'Pedido de recarga broker',
  '/recargas/novo_pedido_importacao_valores': 'Pedido de recarga por importação',
  '/recargas/pedido_recarga_broker/confirmacao_pedido': 'Confirmação de pedido',
}
