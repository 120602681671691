import * as yup from 'yup'

const baseMessage = 'é um campo obrigatório'

export const schema = yup.object().shape({
  nome: yup.string().required('Nome do colaborador' + baseMessage),
  cpf: yup.string().required('CPF' + baseMessage),
  nomeDaMae: yup.string().required('Nome da mãe' + baseMessage),
  sexo: yup.string().required('Sexo' + baseMessage),
  dataNascimento: yup.string().nullable().required('Data de nascimento'),
  cep: yup.string().required('CEP' + baseMessage),
  cidade: yup.string().required('Cidade' + baseMessage),
  uf: yup.string().required('UF' + baseMessage),
  endereco: yup.string().required('Endereço' + baseMessage),
  bairro: yup.string().required('Bairro' + baseMessage),
  numero: yup.string().required('Número' + baseMessage),
})
