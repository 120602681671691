import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Body, Subtitle, Description, Wrapper, AlignRight } from '../styles'
import { useDispatch } from 'react-redux'
import { hideDocument } from '@/utils/functionUtils'
import { Panel, Table, Input, Button, Content, OrderIcon, Spinner, PageHeader } from 'presentation/components'
import paginatorMethods from '@/utils/paginatorMethods'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import { Grid } from 'presentation/components/atoms/grid'
import { Search } from 'presentation/components/molecules/search'
import useDebounce from 'main/hooks/useDebouce'
import useService from 'main/hooks/useService'
import { getEmployeesForCardFirstCopy } from '@/services/employeesServices'
import api from '@/infra/api'
import Moment from 'react-moment'
import { usePaginationOld } from 'main/hooks/usePaginationOld'
import { useNavigate } from 'react-router-dom'
import { TableFooterPagination } from '@/presentation/components/molecules'

const NewCard = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const debounce = useDebounce()
  const [selected, setSelected] = useState([])
  const [isWaitingResponse, setIsWaitingResponse] = useState(false)

  const location = useLocation()

  const operatorCardId = location.state.operatorCardId

  const query = {
    sort: 'integrationCreationDate',
    desc: true,
    operatorCard: operatorCardId,
  }
  const { paginator, setPaginator } = usePaginationOld({ query })

  const [getEmployeesForFirstCopyState, getEmployeesForFirstCopyRequest] = useService(getEmployeesForCardFirstCopy, {
    onError: () => navigate(-1),
    onCompleted: (res) => {
      const pageInfo = res?.data
      setPaginator(
        paginatorMethods.updatePaginator(paginator, {
          operatorCard: operatorCardId,
          page: pageInfo?.pageNumber ? pageInfo?.pageNumber : 1,
          pageSize: pageInfo?.pageSize ? pageInfo?.pageSize : 10,
          totalPages: pageInfo?.totalPages ? pageInfo?.totalPages : 1,
          totalItens: pageInfo?.totalItens ? pageInfo?.totalItens : 0,
        }),
      )
    },
  })

  const employeesData = getEmployeesForFirstCopyState.response?.data?.data
  const isLoading = isWaitingResponse || getEmployeesForFirstCopyState.loading

  useEffect(() => {
    paginator.doRequest &&
      getEmployeesForFirstCopyRequest(paginatorMethods.buildQuery({ ...paginator, operatorCard: operatorCardId }))
    // eslint-disable-next-line
  }, [paginator])

  const handleCheckClick = (id) => {
    const newArray = [...selected]
    const inSelected = newArray.indexOf(id)
    if (inSelected > -1) {
      newArray.splice(inSelected, 1)
      setSelected(newArray)
    } else {
      newArray.push(id)
      setSelected(newArray)
    }
  }

  const generateTableRow = () => {
    return employeesData
      ? employeesData.map((employee) => (
          <Table.Tr key={employee.id}>
            <Table.Td>
              <Input.CheckBox
                id='new_card_order_tbody_select'
                checked={selected.includes(employee.id)}
                controlled
                onClick={() => handleCheckClick(employee.id)}
              />
            </Table.Td>
            <Table.Td id='new_card_order_tbody_employee'>{employee.fullname}</Table.Td>
            <Table.Td id='new_card_order_tbody_date_register'>
              {!employee.integrationCreationDate ? null : (
                <Moment format='DD/MM/YYYY'>{employee.integrationCreationDate}</Moment>
              )}
            </Table.Td>
            <Table.Td id='new_card_order_tbody_registration'>{employee?.registration}</Table.Td>
            <Table.Td id='new_card_order_tbody_document'>{hideDocument(employee.cpf)}</Table.Td>
          </Table.Tr>
        ))
      : null
  }

  const requestOrder = async () => {
    if (selected.length < 1) return
    setIsWaitingResponse(true)

    try {
      const response = await api.post(`rh-bff/cards/new/first?operatorCard=${operatorCardId}`, selected)

      navigate('/cartoes/novo/primeira', {
        state: { orderId: response.data },
      })

      setIsWaitingResponse(false)
    } catch (error) {
      setIsWaitingResponse(false)
      dispatch(pushNotification(errorHandler(error.response)))
      await getEmployeesForFirstCopyRequest(paginatorMethods.buildQuery({ ...paginator, operatorCard: operatorCardId }))
      return false
    }
  }

  return (
    <Content>
      <Panel noPadding>
        <PageHeader id='new_card_order_title'>Novo Pedido de Cartão</PageHeader>
        <Body>
          <Subtitle id='new_card_order_subtitle'>Pedido 1ª Via</Subtitle>
          <Description>
            Selecione abaixo os colaboradores para os quais deseja fazer o pedido de <strong>1ª via de cartão</strong>
          </Description>
          <Grid container sx={{ marginBottom: '20px' }}>
            <Grid item sm={12} md={6} lg={4}>
              <Search
                id='new_card_order_search'
                placeholder='Buscar na tabela abaixo...'
                onSearch={(value) =>
                  debounce(() =>
                    setPaginator(
                      paginatorMethods.setFilters(paginator, {
                        filterProps: 'registration,fullname,cpf',
                        filterValue: value,
                      }),
                    ),
                  )
                }
              />
            </Grid>
          </Grid>
          {isLoading ? (
            <Spinner.Box>
              <Spinner />
            </Spinner.Box>
          ) : (
            <>
              <Table loading={isLoading}>
                <thead>
                  <Table.Tr>
                    <Table.Th>
                      <Input.CheckBox
                        id='new_card_order_thead_select'
                        checked={selected?.length === employeesData?.length}
                        controlled
                        onClick={() => {
                          if (selected?.length === employeesData?.length) {
                            setSelected([])
                          } else {
                            setSelected(employeesData.map((employee) => employee.id))
                          }
                        }}
                      />
                    </Table.Th>
                    <Table.Th>
                      <Wrapper id='new_card_order_thead_employee'>
                        Colaborador
                        <OrderIcon
                          clickUp={() => {
                            setPaginator(
                              paginatorMethods.setFilters(paginator, {
                                sort: 'fullname',
                                desc: false,
                              }),
                            )
                          }}
                          clickDown={() => {
                            setPaginator(
                              paginatorMethods.setFilters(paginator, {
                                sort: 'fullname',
                                desc: true,
                              }),
                            )
                          }}
                        />
                      </Wrapper>
                    </Table.Th>
                    <Table.Th>
                      <Wrapper id='new_card_order_thead_date_register'>
                        Data Cadastro
                        <OrderIcon
                          clickUp={() => {
                            setPaginator(
                              paginatorMethods.setFilters(paginator, {
                                sort: 'integrationCreationDate',
                                desc: false,
                              }),
                            )
                          }}
                          clickDown={() => {
                            setPaginator(
                              paginatorMethods.setFilters(paginator, {
                                sort: 'integrationCreationDate',
                                desc: true,
                              }),
                            )
                          }}
                        />
                      </Wrapper>
                    </Table.Th>
                    <Table.Th>
                      <Wrapper id='new_card_order_thead_registration'>
                        Matrícula
                        <OrderIcon
                          clickUp={() => {
                            setPaginator(
                              paginatorMethods.setFilters(paginator, {
                                sort: 'registration',
                                desc: false,
                              }),
                            )
                          }}
                          clickDown={() => {
                            setPaginator(
                              paginatorMethods.setFilters(paginator, {
                                sort: 'registration',
                                desc: true,
                              }),
                            )
                          }}
                        />
                      </Wrapper>
                    </Table.Th>
                    <Table.Th>
                      <Wrapper id='new_card_order_thead_document'>Documento</Wrapper>
                    </Table.Th>
                  </Table.Tr>
                </thead>
                <tbody>{generateTableRow()}</tbody>
              </Table>
              <div style={{ marginTop: '16px' }}>
                <TableFooterPagination
                  onPageChange={(_e, wantedPage) => setPaginator(paginatorMethods.setPage(paginator, wantedPage))}
                  onRowsPerPageChange={(e) => setPaginator(paginatorMethods.setPageSize(paginator, e.target.value))}
                  page={paginator.page}
                  rowsPerPage={paginator.rowsPerPage}
                  totalItems={paginator.totalItens}
                  totalPages={paginator.totalPages}
                  rowsPerPageOptions={[10, 20, 50, 100, 1000]}
                  hideDivider
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <AlignRight>
                  <Button onClick={() => navigate('/cartoes')} id='new_card_order_button_cancel'>
                    Cancelar
                  </Button>
                  <div style={{ marginLeft: '15px' }} />
                  <Button variant='action-outlined' onClick={() => requestOrder()} id='new_card_order_button_confirm'>
                    Confirmar
                  </Button>
                </AlignRight>
              </div>
            </>
          )}
        </Body>
      </Panel>
    </Content>
  )
}

export default NewCard
