import { ORDER_STATUS, ORDER_STATUS_KEY } from 'main/utils/constants'

interface IWaitingPaymentStatus {
  status: number
  isFinancedSales: boolean
}

export const waitingPaymentStatus = ({ status, isFinancedSales }: IWaitingPaymentStatus) => {
  switch (status) {
    case 3: {
      if (isFinancedSales) {
        return 'Pedido enviado'
      }

      return ORDER_STATUS[ORDER_STATUS.WAITING_PAYMENT as ORDER_STATUS_KEY]
    }
    default: {
      if (isFinancedSales) {
        return 'Pedido enviado'
      }

      return status > ORDER_STATUS.WAITING_PAYMENT && status === 99
        ? 'Processando Pagamento'
        : status > ORDER_STATUS.WAITING_PAYMENT
        ? 'Pagamento confirmado'
        : ORDER_STATUS[ORDER_STATUS.WAITING_PAYMENT as ORDER_STATUS_KEY]
    }
  }
}
