import paginatorMethods from '@/utils/paginatorMethods'

import { useAccessProfile } from '@/main/hooks/useAccessProfile'
import { TableCell, TableRow, TableHead as Head } from 'presentation/components/atoms'
import { Wrapper } from '../../../../styles'

import { OrderIcon } from 'presentation/components'

export const TableHead = ({ setPaginator, paginator }) => {
  const { resources } = useAccessProfile({ resource: 'cartoes' })

  return (
    <Head>
      <TableRow>
        <TableCell id='cards_head_nome_cartao'>Nome Cartão</TableCell>
        <TableCell id='cards_head_numero_cartao'>
          <Wrapper>
            Nº Cartão
            <OrderIcon
              clickUp={() => setPaginator(paginatorMethods.setOrder(paginator, 'number'))}
              clickDown={() => setPaginator(paginatorMethods.setOrder(paginator, 'number', false))}
            />
          </Wrapper>
        </TableCell>
        <TableCell id='cards_head_colaborador'>
          <Wrapper>
            Colaborador
            <OrderIcon
              clickUp={() => setPaginator(paginatorMethods.setOrder(paginator, 'employeeName'))}
              clickDown={() => setPaginator(paginatorMethods.setOrder(paginator, 'employeeName', false))}
            />
          </Wrapper>
        </TableCell>
        <TableCell id='cards_head_matricula_operadora'>Matrícula Operadora</TableCell>
        <TableCell id='cards_head_beneficio'>Benefício</TableCell>
        <TableCell id='cards_head_valor_diario'>Valor Diário</TableCell>
        {resources({ item: 'cartoes_saldo' }) ? <TableCell id='cards_head_saldo'>Saldo</TableCell> : null}
        <TableCell id='cards_head_status'>Status</TableCell>
        <TableCell style={{ textAlign: 'center' }} id='cards_acoes'>
          Ações
        </TableCell>
      </TableRow>
    </Head>
  )
}
