import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { useAtomValue } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { orderIdNewApiAtom, purchaseIdNewApiAtom } from '@/main/store'
import api from '@/infra/api'
import { ETipoPagamento, ETipoPedido } from '@/main/enums'
import { IFetchHook, OrderPaymentType } from '@/main/interfaces'
import { DetailsApiReturn, ItemsApiReturn, Response, SiblingsApiReturn } from './utils/interfaces'
import { equalizeStatus } from './utils/functions/equalizeStatus'
import { IOrderItems } from './utils/interfaces/iOrderItems'
import { IUseOrderTrackingReturn } from './utils/interfaces/iUseOrderTrackingReturn'

// Need to equalize the actual status with the old api ones

const extractPaymentTypes = (payments: Response['pagamentoCompra']['pagamentos']) => {
  const statusMap = {
    [ETipoPagamento.Boleto]: 'SLIP',
    [ETipoPagamento.CarteiraOperadora]: 'TICKETING_BALANCE',
    [ETipoPagamento.CarteiraSK]: 'ACCOUNT_BALANCE',
    [ETipoPagamento.Gratuito]: 'FREE',
    [ETipoPagamento.Pix]: 'PIX',
  }
  return payments.map((payment) => statusMap[payment.tipo]) as OrderPaymentType[]
}

const getPayment = (paymentType: ETipoPagamento, payments: Response['pagamentoCompra']['pagamentos']) => {
  const payment = payments.find((pay) => pay.tipo === paymentType)
  return payment
}

export const useOrderTracking = ({ filter, pagination, immutableQuery }: IFetchHook): IUseOrderTrackingReturn => {
  const navigate = useNavigate()
  const purchaseId = useAtomValue(purchaseIdNewApiAtom)
  const orderId = useAtomValue(orderIdNewApiAtom)

  //main order data
  const { data, error, ...rest } = useQuery<AxiosResponse<DetailsApiReturn>>({
    queryKey: ['newOrderDetail', orderId, purchaseId],
    queryFn: () => api.get(`rh-pedidos-bff/pedido/detalhamento?idPedido=${orderId}&idCompra=${purchaseId}`),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: orderId !== '',
  })

  // order items
  const { data: itemsData, isLoading: isItemsLoading } = useQuery<AxiosResponse<ItemsApiReturn>>({
    queryKey: ['newOrderDetailItems', orderId, purchaseId, pagination, filter, immutableQuery],
    queryFn: () =>
      api.get(`rh-pedidos-bff/pedido/detalhamento/itens?idPedido=${orderId}`, {
        params: { ...pagination, ...filter, ...immutableQuery, id: orderId },
      }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: orderId !== '',
  })

  // sibling orders
  const { data: siblingData, isLoading: isLoadingSiblings } = useQuery<AxiosResponse<SiblingsApiReturn>>({
    queryKey: ['newOrderSiblingsOrders', purchaseId, pagination, filter, immutableQuery],
    queryFn: () =>
      api.get(`rh-pedidos-bff/pedido/detalhamento/pedidos?idCompra=${purchaseId}`, {
        params: { ...pagination, ...filter, ...immutableQuery },
      }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: orderId !== '',
  })

  useEffect(() => {
    if (error) navigate('/recargas')
  }, [error, navigate])

  const organizeItemsData = (): IOrderItems => {
    const rawItems = itemsData?.data?.valor?.colaboradores
    if (!rawItems) return {} as IOrderItems
    const items = rawItems.map((employee) => ({
      cardNumber: employee.numeroCartao,
      employeeName: employee.nomeColaborador,
      employeeRegistration: employee.matriculaColaborador,
      operatorRegistration: employee.matriculaNaOperadora,
      fees: employee.itens.map((item) => ({
        days: item.qtdDias,
        dayValue: item.valorDiario,
        total: item.valorRecarga,
        operatorName: employee.nomeProduto,
      })),
    }))
    return {
      data: items,
      page: itemsData?.data?.valor?.pagina || 1,
      pageSize: pagination.qtdLinhas as any,
      totalItems: itemsData?.data?.valor?.qtdColaboradores,
      totalPages: itemsData?.data?.valor?.qtdPaginas,
    }
  }

  const organizeCartOrders = (actualOrderCode: number) => {
    const rawData = siblingData?.data?.valor
    if (!rawData) return []
    return rawData.reduce((final, sibling) => {
      if (sibling.codigo === actualOrderCode) return final
      return [
        ...final,
        {
          code: String(sibling.codigo),
          id: String(sibling.id),
          operatorName: sibling.nomeOperadora,
          status: equalizeStatus(sibling.status, 0),
          totalItems: sibling.qtdItensUnicos,
          totalValue: sibling.valorTotal,
          type: sibling.tipo,
        },
      ]
    }, [])
  }

  const organizeData = (): IUseOrderTrackingReturn['order'] => {
    const rawData = data?.data?.valor
    if (!rawData) return null
    const orderStatus = equalizeStatus(rawData.pedidoDetalhado.status, rawData.statusCompra)
    const cartOrders = organizeCartOrders(rawData.pedidoDetalhado.codigo)
    const slip = getPayment(ETipoPagamento.Boleto, rawData.pagamentoCompra.pagamentos)
    const pix = getPayment(ETipoPagamento.Pix, rawData.pagamentoCompra.pagamentos)
    const walletUsage = getPayment(ETipoPagamento.CarteiraSK, rawData.pagamentoCompra.pagamentos)
    return {
      orderCode: String(rawData.pedidoDetalhado.codigo),
      orderId: orderId,
      orderDate: new Date(rawData.pedidoDetalhado.dataCriacao).toLocaleDateString(),
      cancelDate: rawData.pedidoDetalhado.dataCancelamento,
      orderOperatorName: rawData.pedidoDetalhado.nomeOperadora,
      orderStatus: orderStatus,
      status: orderStatus,
      orderType: ETipoPedido.RecargaVtBroker,
      totalItems: 0, // Utilizado pela integraçao antiga
      usedPaymentsTypes: extractPaymentTypes(rawData.pagamentoCompra.pagamentos),
      orderSummary: {
        administrativeFee: rawData.pedidoDetalhado.pagamento.taxaAdministrativa,
        issFee: rawData.pedidoDetalhado.pagamento.retencaoIss,
        processingFee: 0, //remover
        transferFee: rawData.pedidoDetalhado.pagamento.taxaRepasse,
        walletUsage: 0, //old integration, remove when not needed anymore
        orderValue: rawData.pedidoDetalhado.pagamento.valorRecarga,
        orderTotal: rawData.pedidoDetalhado.pagamento.valorTotal,
        totalPaid: rawData.pedidoDetalhado.pagamento.valorTotalPago,
        ticketingBalance: rawData.pedidoDetalhado.pagamento.valorUsoCarteiraOperadora,
      },
      cart: {
        hasFirstCopy: false, // vai ser utilizado no futuro
        hasSecondCopy: false, // vai ser utilizado no futuro
        hasRecharge: true, // vai ser utilizado no futuro
        cartOrders: cartOrders,
        purchaseId: purchaseId,
        cartSummary: {
          administrativeFee: rawData.pagamentoCompra.taxaAdministrativa,
          issFee: rawData.pagamentoCompra.retencaoIss,
          orderTotal: rawData.pagamentoCompra.valorTotal,
          processingFee: rawData.pagamentoCompra.taxaProcessamento,
          totalPaid: rawData.pagamentoCompra.valorTotalPago,
          transferFee: rawData.pagamentoCompra.taxaRepasse,
          ticketingBalance: rawData.pagamentoCompra.pagamentos.find(
            (payment) => payment.tipo === ETipoPagamento.CarteiraOperadora,
          )?.valor,
          walletUsage: walletUsage?.valor,
          firstCopy: 0, // deve ser integrado no futuro
          secondCopy: 0, // deve ser integrado no futuro
          rechargeValue: rawData.pagamentoCompra.valorRecarga,
          totalInOrders: 0, //sem uso na nova integracao
        },
        code: String(rawData.codigoCompra),
        id: 'purchase',
      },
      slip: {
        barcodeNumber: slip?.codigo,
        slipDueDate: slip
          ? new Date(slip?.dataVencimento).toLocaleDateString()
          : pix
          ? new Date(pix?.dataVencimento).toLocaleDateString()
          : null,
        slipLink: rawData.pagamentoCompra.urlBoleto,
        value: slip?.valor,
      },
      pix: {
        qrCode: pix?.codigo,
        value: pix?.valor,
        orderDate: rawData.pedidoDetalhado.dataCriacao,
      },
      orderItems: organizeItemsData(),
    }
  }

  const organizedData = organizeData()

  return {
    order: organizedData,
    isItemsLoading,
    ...rest,
    isLoading: isLoadingSiblings || rest.isLoading,
  }
}
