import { ORDER_STATUS, ORDER_STATUS_KEY } from 'main/utils/constants'

interface IWaitingPaymentStatus {
  status: number
  isFinancedSales: boolean
  isRecharge: boolean
}

export const runningPaymentStatus = ({ status, isFinancedSales, isRecharge }: IWaitingPaymentStatus) => {
  switch (status) {
    case 3: {
      if (isFinancedSales) {
        return 'Boleto a prazo pendente'
      }

      return ORDER_STATUS[ORDER_STATUS.RUNNING as ORDER_STATUS_KEY]
    }
    default: {
      if (isFinancedSales) {
        return 'Boleto a prazo pendente'
      }

      return [ORDER_STATUS.PROCESSING, ORDER_STATUS.RUNNING, ORDER_STATUS.WAITING_FOR_PROCESSING].includes(status)
        ? ORDER_STATUS[status as ORDER_STATUS_KEY]
        : isRecharge
        ? ORDER_STATUS[ORDER_STATUS.WAITING_FOR_PROCESSING as ORDER_STATUS_KEY]
        : ORDER_STATUS[ORDER_STATUS.RUNNING as ORDER_STATUS_KEY]
    }
  }
}
