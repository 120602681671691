import { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  Typography,
} from '@stationkim/front-ui'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { CheckableCard } from './checkableCard/checkableCard'
import { Option } from '@/presentation/pages/paymentConfirmation/paymentConfirmation'

interface OperatorSelectorProps {
  open: boolean
  onClose: () => void
  onConfirm: (value: Array<string | number>) => void
  options: Option[]
  defaultValue?: Array<string | number>
}

export const OperatorSelector = ({ open, onClose, onConfirm, defaultValue = [], options }: OperatorSelectorProps) => {
  const [selected, setSelected] = useState<Array<string | number>>(defaultValue)

  const handleOptionCheck = (id: string | number) => {
    setSelected((state) => {
      const newState = [...state]
      const optionIndex = newState.findIndex((optionId) => optionId === id)
      if (optionIndex >= 0) {
        newState.splice(optionIndex, 1)
      } else newState.push(id)
      return newState
    })
  }

  const handleConfirm = () => {
    onConfirm && onConfirm(selected)
  }

  const renderOptions = () => {
    return (
      <Grid container spacing='16px'>
        {options.map((option) => (
          <Grid item key={option.value} xs={6}>
            <CheckableCard
              onChange={() => handleOptionCheck(option.value)}
              option={{ label: option.label, value: option.value }}
              checked={selected.includes(option.value)}
            />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <DialogTitle>Selecionar operadoras</DialogTitle>
        <Icon icon={faClose} sx={{ fontSize: '1.5rem', cursor: 'pointer' }} onClick={onClose} />
      </Box>
      <DialogContent>
        <Typography variant='body2'>
          Selecione as operadoras para as quais você fará a projeção de saldo neste pedido.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '16px' }}>{renderOptions()}</Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', marginTop: '32px' }}>
        <Button variant={'outlined'} onClick={onClose} fullWidth sx={{ filter: 'grayscale(100)' }}>
          Cancelar
        </Button>
        <Button variant={'contained'} onClick={handleConfirm} fullWidth>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
