import { useContext } from 'react'
import { Controller } from 'react-hook-form'
import { useAtomValue } from 'jotai'
import { Box, MaskedTextField, Typography, formatMoney } from '@stationkim/front-ui'
import { AccountPersistentStore } from '@/main/store'
import { PaymentConfirmationContext } from '@/presentation/pages/paymentConfirmation/paymentConfirmation'

const fieldMask = {
  mask: 'R$ num',
  lazy: true,
  blocks: {
    num: {
      mask: Number,
      scale: 2,
      thousandsSeparator: '.',
      radix: ',',
      mapToRadix: ['.'],
    },
  },
}

const walletNameFallback = 'Carteira Digital'
export const WalletUsage = () => {
  const { form, walletBalance, order } = useContext(PaymentConfirmationContext)
  const totalBeforeBalanceUsage = order?.detailedValues.totalBeforeDynamicValues
  const account = useAtomValue(AccountPersistentStore)
  const walletName = account?.resale?.digitalAccountName || walletNameFallback
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: '32px' }}>
        <Typography variant='subtitle2' sx={{ whiteSpace: 'nowrap' }}>
          Saldo {walletName}
        </Typography>
        <Typography sx={{ whiteSpace: 'nowrap' }}>
          <small>R$</small> {formatMoney(walletBalance || 0)}
        </Typography>
      </Box>
      <Typography
        variant='label2'
        sx={{ fontWeight: 400, marginBottom: '16px', display: 'block', margin: '8px 0 16px 0' }}
      >
        Para usar {walletName} no pagamento deste pedido, digite o valor abaixo.
      </Typography>
      <Controller
        control={form.control}
        name='walletUsage'
        rules={{
          validate: (value) => {
            if (Number(value) > totalBeforeBalanceUsage)
              return `Você não pode exceder o valor total a ser pago de R$ ${formatMoney(totalBeforeBalanceUsage)}`
            if (Number(value) > walletBalance) {
              return `Você não pode exceder o seu saldo atual de R$ ${formatMoney(walletBalance)}`
            }
            return true
          },
        }}
        render={({ field: { value, onChange, ref }, fieldState: { error } }) => {
          return (
            <MaskedTextField
              inputRef={ref}
              mask={fieldMask}
              helperText={error?.message}
              error={Boolean(error?.message)}
              label={'Valor de uso ' + walletName}
              placeholder='R$ 00,00'
              disabled={walletBalance <= 0}
              value={value}
              onChange={onChange}
              unmask
            />
          )
        }}
      />
    </>
  )
}
