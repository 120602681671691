import { Box, Button, Divider, Paper, Typography } from 'presentation/components/atoms'
import { Items } from '../Items/items'
import { useContext, useEffect, useState } from 'react'
import { CancelCartDialog } from './cancelCartDialog'
import { CartContext } from '../../cart'
import { formatMoney } from 'utils/functionUtils'
import { DropdownButton } from '@/presentation/components/molecules'
import { DropdownButtonItem } from '@/presentation/components/molecules'
import { useNavigate } from 'react-router-dom'
import { Buttons } from './styles'
import api from '@/infra/api'
import { BROKER_OPTIONS } from '@/utils/constants'
import { useOperatorCards } from '@/main/hooks/useOperatorCards'
import { useOrderOperatorsCards } from '../../hooks/useOrderOperatorsCards'
const buyMoreButtonId = 'cart_buyMore_button'
export const Details = ({ cart, totalItems }: { cart: any; totalItems: number }) => {
  const navigate = useNavigate()

  const { cards: operators } = useOperatorCards()
  const { operatorsCards } = useOrderOperatorsCards()

  const operatorCardId = operatorsCards?.[0]?.id

  const [showDropDownOtimo, setShowDropDownOtimo] = useState(false)
  const [isShowDropDownOtimoLoading, setIsShowDropDownOtimoLoading] = useState(true)

  useEffect(() => {
    setIsShowDropDownOtimoLoading(true)
    api
      .get(`/rh-bff/departments/isCompanyBrokerOtimo/`)
      .then((e) => {
        setShowDropDownOtimo(e.data)
      })
      .finally(() => setIsShowDropDownOtimoLoading(false))
  }, [])

  const { totalBeforeBalanceUsage, balanceToBeUsed } = useContext(CartContext)
  const [isCartCancelDialogOpen, setIsCartCancelDialogOpen] = useState(false)
  const total = totalBeforeBalanceUsage - balanceToBeUsed

  return (
    <Paper sx={{ padding: '16px' }} elevation={0}>
      <Typography variant='h2' sx={{ marginBottom: '8px' }} id='cart_subtitle'>
        Detalhes do pedido
      </Typography>
      <Typography variant='subtitle2' sx={{ fontWeight: 400 }}>
        Revise as informações do seu pedido, cancele ou conclua sua compra.
      </Typography>
      <Divider sx={{ margin: '16px 0' }} />
      <Box sx={{ marginBottom: '32px', padding: '0 16px' }}>
        <Typography variant='h4' sx={{ margin: '16px 0' }}>
          Resumo
        </Typography>
        <Box sx={{ margin: '16px 0' }}>
          <p>
            {totalItems} {totalItems > 1 ? 'itens' : 'item'} no carrinho
          </p>
        </Box>
      </Box>
      <span>
        <Items id='cart_recharges' orders={cart?.rechargeOrders} title='Recarga' orderType='recharge' />
        <Items id='cart_firstCopies' orders={cart?.firstCopyOrders} title='Cartão 1ª via' orderType='firstCopy' />
        <Items id='cart_secondCopies' orders={cart?.secondCopyOrders} title='Cartão 2ª via' orderType='secondCopy' />
      </span>
      <Box sx={{ marginTop: '32px', display: 'flex', alignItems: 'end', gap: '16px' }}>
        <Typography sx={{ marginLeft: 'auto', fontWeight: 600 }}>Total: </Typography>
        <Typography variant='h4' sx={{ fontWeight: 600 }}>
          <span style={{ fontWeight: 400 }}>R$ </span> {formatMoney(total)}
        </Typography>
      </Box>
      <Buttons>
        <Button
          id={'cart_cancel_button'}
          variant='outlined'
          sx={{ marginTop: '32px', filter: 'grayscale(100%)' }}
          onClick={() => setIsCartCancelDialogOpen(true)}
        >
          Cancelar Pedido
        </Button>
        <DropdownButton
          label='Continuar comprando'
          size='large'
          sx={{ marginTop: '32px' }}
          variant='outlined'
          isLoading={isShowDropDownOtimoLoading}
          id={buyMoreButtonId}
        >
          <DropdownButtonItem
            id={buyMoreButtonId + '_option_0'}
            onClick={() =>
              navigate('/recargas/novo_pedido_manual', {
                state: {
                  operatorCardId,
                },
              })
            }
          >
            Pedido manual
          </DropdownButtonItem>
          {showDropDownOtimo && (
            <DropdownButtonItem
              id={buyMoreButtonId + '_option_1'}
              onClick={() =>
                navigate('/recargas/pedido_recarga_broker', {
                  state: { broker: BROKER_OPTIONS.OTIMO, operatorCardId },
                })
              }
            >
              Pedido Broker Ótimo
            </DropdownButtonItem>
          )}
          <DropdownButtonItem
            id={buyMoreButtonId + '_option_2'}
            onClick={() =>
              navigate('/recargas/novo_pedido_importacao', {
                state: {
                  operatorCardId,
                },
              })
            }
          >
            Pedido por importação
          </DropdownButtonItem>
          <DropdownButtonItem
            id={buyMoreButtonId + '_option_3'}
            onClick={() => {
              ;() =>
                operators.length <= 1
                  ? navigate('/cartoes/novo', {
                      state: {
                        operatorCardId: operators?.[0]?.operatorCardId,
                        operatorCardName: operators?.[0]?.operatorCardName,
                        operatorName: operators?.[0]?.operatorName,
                      },
                    })
                  : navigate('/cartoes/novo/selecionar')
            }}
          >
            1ª via de cartão
          </DropdownButtonItem>
          <DropdownButtonItem id={buyMoreButtonId + '_option_4'} onClick={() => navigate('/cartoes/novo/segunda')}>
            2ª via de cartão
          </DropdownButtonItem>
        </DropdownButton>
      </Buttons>
      <CancelCartDialog open={isCartCancelDialogOpen} setOpen={setIsCartCancelDialogOpen} />
    </Paper>
  )
}
