import { faFile, faImage } from '@fortawesome/free-solid-svg-icons'
import { Typography } from 'presentation/components/atoms/typography'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone-esm'
import { Icon } from 'presentation/components/atoms/Icon'
import { StyledDropzone } from './styles'

function humanFileSize(size) {
  var i = Number(size) === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}

export const Dropzone = ({
  name,
  id,
  accept,
  acceptedFormats,
  initialValue,
  value,
  onChange,
  recomendationText,
  defaultIcon,
  maxSize,
  minSize,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState(initialValue)
  const file = value ? value : internalValue
  const setFile = value ? onChange : setInternalValue
  const [dropErrors, setDropErrors] = useState(null)

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0])
      setDropErrors(null)
    }
    if (rejectedFiles.length > 0) {
      if (rejectedFiles[0]) {
        setDropErrors(rejectedFiles[0].errors)
      }
    }
    // eslint-disable-next-line
  }, [])

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: accept,
    maxSize: maxSize,
    minSize: minSize,
    onDrop,
  })

  const renderSupportedFormats = () => {
    let acceptedFormats = []
    const mimeTypes = Object.keys(accept)
    mimeTypes.forEach((mimeType) => (acceptedFormats = [...acceptedFormats, ...accept[mimeType]]))
    let finalString = (acceptedFormats.length > 1 ? 'Formatos suportados: ' : 'Formato suportado:') + acceptedFormats
    return finalString.replaceAll(',', ', ')
  }

  const renderIcon = () => {
    if (defaultIcon) return defaultIcon
    const returnIcon = (type) => {
      if (type.includes('image')) return faImage
      return faFile
    }

    if (accept && !file) {
      const acceptedTypes = Object.keys(accept)
      return returnIcon(acceptedTypes[0])
    }

    if (file) return returnIcon(file.type)
    return faFile
  }

  return (
    <StyledDropzone
      error={dropErrors}
      {...props}
      {...getRootProps({
        isDragAccept,
        isDragReject,
        hasFile: !!file,
      })}
    >
      <input name={name} id={id} {...getInputProps()} />
      <Icon icon={renderIcon()} />

      {isDragReject || dropErrors ? (
        <>
          <Typography variant='h5'>Arquivo não suportado!</Typography>
          {dropErrors.some((error) => error.code === 'file-too-large') ? (
            <Typography variant='body1'>O tamanho máximo de arquivo é {humanFileSize(maxSize)}</Typography>
          ) : null}
        </>
      ) : (
        <>
          {!file ? null : <Typography variant='h5'>{file.name}</Typography>}
          <Typography variant='body1'>
            Para enviar um novo arquivo
            <strong> clique aqui</strong>, ou arraste e solte
          </Typography>
        </>
      )}
      {!accept ? null : <Typography variant='body1'>{renderSupportedFormats()}</Typography>}
      {!recomendationText ? null : <Typography variant='body1'>Recomendado: {recomendationText}</Typography>}
    </StyledDropzone>
  )
}
