import { useContext } from 'react'
import { Theme, Typography } from '@mui/material'
import { Decimal } from 'decimal.js'
import { Box, Button, Divider, formatMoney } from '@stationkim/front-ui'
import { useFeatures } from '@/main/hooks'
import { Method } from './components/method/method'
import { WalletUsage } from './components/walletUsage/walletUsage'
import { Values } from './components/values/values'
import { DaysForCredit } from './components/daysForCredit/daysForCredit'
import { PaymentConfirmationContext } from '../../paymentConfirmation'
import { BalanceProjection } from './components/balanceProjection/balanceProjection'
import { useLocation } from 'react-router-dom'

const dividerStyle = { margin: '32px 0' }

export const Payment = () => {
  const { form, order, confirmOrder } = useContext(PaymentConfirmationContext)
  const { features } = useFeatures()
  const location = useLocation()
  const purchaseId = location.state?.purchaseId
  const detailedValues = order.detailedValues
  const [walletUsage, dayForCredit] = form.watch(['walletUsage', 'dayForCredit'])
  const isRecharge = true
  const orderTotalAfterBalanceUsage = new Decimal(detailedValues.totalBeforeDynamicValues).minus(walletUsage || 0)

  const onSubmit = (data) => {
    return confirmOrder.mutate({
      balanceUsage: data.walletUsage,
      creditDate: data.dayForCredit,
      method: data.paymentMethod,
      operatorsToUseBalanceProjection: data.selectedOperatorsForBalanceProjection,
      purchaseId,
      totalBeforeBalanceUsage: order?.detailedValues?.totalBeforeDynamicValues,
    })
  }

  return (
    <Box
      sx={(theme: Theme) => ({
        backgroundColor: theme.palette.grey[50],
        borderRadius: '8px',
        padding: '16px',
      })}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Typography variant='h2' sx={{ marginBottom: '32px' }}>
          Pagamento
        </Typography>
        <Method />
        <WalletUsage />
        <Divider sx={dividerStyle} />
        <DaysForCredit />
        <Divider sx={dividerStyle} />
        <Typography variant='subtitle2' sx={{ marginBottom: '32px' }}>
          Resumo da compra
        </Typography>
        <Values
          values={[
            {
              label: `Valor ${isRecharge ? 'da recarga' : 'do pedido'}`,
              value: formatMoney(detailedValues.orderValue),
              prefix: 'R$ ',
            },
            {
              label: 'Taxas administrativas',
              value: formatMoney(detailedValues.administrativeFees),
              prefix: 'R$ ',
              visible: detailedValues.administrativeFees > 0,
            },
            {
              label: 'Taxa de repasse',
              value: formatMoney(detailedValues.transferFee),
              prefix: 'R$ ',
              visible: detailedValues.transferFee > 0,
            },
            {
              label: 'Conta corrente total',
              value: formatMoney(detailedValues.ticketingBalanceUsage),
              prefix: 'R$ -',
              visible: features.ticketingBalance && detailedValues.ticketingBalanceUsage > 0,
              color: 'green',
            },
            {
              label: 'Retenção ISS',
              value: detailedValues.issFee,
              prefix: 'R$ -',
              color: 'green',
              visible: detailedValues.issFee > 0,
            },
            {
              label: 'Carteira digital',
              value: walletUsage,
              prefix: walletUsage > 0 ? 'R$ -' : 'R$ ',
              color: walletUsage > 0 ? 'green' : 'grey',
            },
            { label: 'Data para crédito', value: dayForCredit ? dayForCredit?.format('DD/MM/YYYY') : '00/00/0000' },
          ]}
        />
        <Divider sx={dividerStyle} />
        <Values
          values={[
            {
              label: 'Total a ser pago',
              value: formatMoney(orderTotalAfterBalanceUsage.toNumber()),
              isBold: true,
              size: 'md',
              color: 'black',
              prefix: 'R$ ',
            },
          ]}
        />
        <BalanceProjection />
        <Button color='success' variant='contained' type='submit' sx={{ color: 'white', marginTop: '32px' }} fullWidth>
          Concluir Pedido
        </Button>
      </form>
    </Box>
  )
}
