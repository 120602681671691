import { useMemo } from 'react'
import { Theme, useTheme } from '@mui/material'
import { Box, Typography } from '@stationkim/front-ui'

interface IValue {
  label: string
  value: string | number
  color?: 'green' | 'red' | 'black' | 'grey'
  prefix?: string
  isBold?: boolean
  visible?: boolean
  size?: 'sm' | 'md'
}

export interface ValuesSummaryProps {
  values: Array<IValue | null>
  rowSpacing?: string
}

const getTextColors = (theme: Theme) => ({
  grey: theme.palette.grey[700],
  green: theme.palette.success.dark,
  red: theme.palette.error.dark,
  black: theme.palette.common.black,
})

export const Values = ({ values }: ValuesSummaryProps) => {
  const theme = useTheme()
  const textColors = useMemo(() => getTextColors(theme), [theme])
  return (
    <Box>
      {values.map((value: IValue | null) => {
        const { label, prefix = '', isBold, visible = true, size = 'sm' } = value
        const fontWeight = isBold ? 600 : 400
        const fontSize = size === 'sm' ? '.875rem' : '1rem'

        if (!visible) return null
        return (
          <Box
            key={value.label.replaceAll(' ', '')}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              '&:not(:last-of-type)': { marginBottom: '24px' },
            }}
          >
            <Typography sx={{ fontWeight, fontSize }}>{label} &nbsp;</Typography>
            <Typography
              sx={{
                fontWeight,
                fontSize,
                color: textColors[value.color || 'grey'],
              }}
            >
              {prefix}
              {value.value}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}
